import { useAppSelector } from '../../../../../hooks';
import { Permissions, TextColor } from '../../../../../types';
import { Accordion, Typography, TypographySize } from '../../../../shared';
import ItemWrapper from '../ItemWrapper';
import ProspectDesignerItem from './ProspectDesignerItem';
import ProspectHotlineItem from './ProspectHotlineItem';

enum CustomProspectItemType {
  PROSPECT_HOTLINE = 'PROSPECT_HOTLINE',
  PROSPECT_DESIGNER = 'PROSPECT_DESIGNER',
}

interface CustomProspectTabProps {
  onClose: () => void;
}

const CustomProspectTab = ({ onClose }: CustomProspectTabProps) => {
  const user = useAppSelector((state) => state.auth.user);
  const permissions = user?.permissions || [];
  const isSuperAdmin = permissions.includes(Permissions.ADMIN_ACCESS);

  const items = [
    {
      id: CustomProspectItemType.PROSPECT_HOTLINE,
      title: 'Prospect hotline',
      content: <ProspectHotlineItem onClose={onClose} />,
    },
    {
      id: CustomProspectItemType.PROSPECT_DESIGNER,
      title: 'Prospect designer',
      content: <ProspectDesignerItem onClose={onClose} />,
      disabled: !isSuperAdmin,
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
        Tailor a new Practice Prospect to your ICP and scenario.
      </Typography>
      <Accordion items={items.map((item) => ({ ...item, content: <ItemWrapper>{item.content}</ItemWrapper> }))} />
    </div>
  );
};

export default CustomProspectTab;
