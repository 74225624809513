import * as RadixTabs from '@radix-ui/react-tabs';
import clsx from 'clsx';
import { Orientation } from '../../../types';
import { Tab, TabsType } from './Tabs.types';
import TabsTrigger from './TabsTrigger';

interface TabsListProps {
  activeTab: string;
  orientation: Orientation;
  tabs: Tab[];
  type: TabsType;
  setActiveTab: (newActiveTab: string) => void;
}

const TabsList = ({ activeTab, orientation, tabs, type, setActiveTab }: TabsListProps) => {
  return (
    <RadixTabs.List
      className={clsx(
        'flex',
        orientation === Orientation.VERTICAL && 'flex-col',
        type !== TabsType.BORDERED && 'gap-1',
        type === TabsType.BOXED && 'rounded-lg bg-base-300 p-1'
      )}
    >
      {tabs.map((tab) => (
        <TabsTrigger key={tab.id} activeTab={activeTab} tab={tab} type={type} setActiveTab={setActiveTab} />
      ))}
    </RadixTabs.List>
  );
};

export default TabsList;
