import clsx from 'clsx';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import WaveSurfer from 'wavesurfer.js';
import { CALL_SPEAKER_TO_LABEL, SINGLE_AUDIO_WAVE_HEIGHT } from '../../constants';
import { useAppSelector } from '../../hooks';
import { CallSpeaker, ComponentSize, Orientation } from '../../types';
import { formatSecondsToDuration } from '../../utils';
import { Divider, Spinner, Typography, TypographySize } from '../shared';
import CallerSection from './CallerSection';
import CommentsPanel from './CommentsPanel/';
import ControlButtons from './ControlButtons';
import MediaControls from './MediaControls';
import MediaPlayerActions from './MediaPlayerActions';
import useMediaPlayerSeek from './useMediaPlayerSeek';

// Mapping of speaker types to their respective colors.
const SPEAKER_TO_COLOR: Record<CallSpeaker, string> = {
  [CallSpeaker.CUSTOMER]: '#044934',
  [CallSpeaker.AGENT]: '#0AAD7C',
};

const MediaPlayer = forwardRef<HTMLDivElement>((_, ref) => {
  // Check if the call details drawer is open by checking if the callSid is in the URL params.
  const isCallDetailsDrawerOpen = !!useParams().callSid;

  const waveformRef = useRef<HTMLDivElement | null>(null);
  const waveSurfer = useRef<WaveSurfer | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  // State to manage the mounted state of the media player.
  const [isDrawerMounted, setIsDrawerMounted] = useState(false);

  // Redux
  const isSettingsModalOpen = useAppSelector((state) => state.modal.settings.isOpen);
  const { calls, currentCallDetails } = useAppSelector((state) => state.callHistory);
  const { autoPlay, callSid = '' } = currentCallDetails || {};
  const call = calls.find((call) => call.callSid === callSid);

  // Custom hook to handle seeking within the media player based on query parameters or Redux state.
  useMediaPlayerSeek(callSid, duration, waveSurfer);

  useEffect(() => {
    if (!callSid) {
      setIsDrawerMounted(false);
    } else if (call) {
      setIsDrawerMounted(true);
    }
  }, [call, callSid]);

  // Renders the legend element for a speaker.
  const renderLegendElement = useCallback(
    (speaker: CallSpeaker) => (
      <div className="flex items-center gap-2">
        <div className="inline-block h-4 w-4 rounded-full" style={{ backgroundColor: SPEAKER_TO_COLOR[speaker] }} />
        <Typography size={TypographySize.H5}>{CALL_SPEAKER_TO_LABEL[speaker]}</Typography>
      </div>
    ),
    []
  );

  // Effect hook to initialize WaveSurfer and handle audio loading.
  useEffect(() => {
    if (!waveformRef.current || !call?.audioPath) return;

    // Create a WaveSurfer instance.
    waveSurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      dragToSeek: true,
      waveColor: '#C5CBC9',
      height: SINGLE_AUDIO_WAVE_HEIGHT,
      barWidth: 2,
      barGap: 1.5,
      barRadius: 1.5,
      normalize: true,
      autoplay: !!autoPlay,
      cursorColor: '#C5CBC9',
      splitChannels: [
        { progressColor: SPEAKER_TO_COLOR[CallSpeaker.AGENT], barAlign: 'bottom' },
        { progressColor: SPEAKER_TO_COLOR[CallSpeaker.CUSTOMER], barAlign: 'top' },
      ],
    });

    // Error handler for WaveSurfer.
    waveSurfer.current.on('error', (error) => {
      console.error('WaveSurfer error:', error);
      setIsLoading(false);
    });

    // Set the duration when the audio is ready.
    waveSurfer.current.on('ready', () => {
      if (waveSurfer.current) {
        setDuration(waveSurfer.current.getDuration());
        setIsLoading(false);
      }
    });

    // Update the current time as the audio plays.
    waveSurfer.current.on('audioprocess', () => {
      if (waveSurfer.current) {
        setCurrentTime(waveSurfer.current.getCurrentTime() || 0);
      }
    });

    // Updates the current time as the audio seeks.
    waveSurfer.current.on('seeking', function (position) {
      if (waveSurfer.current) {
        setCurrentTime(position);
      }
    });

    // Restarts the audio from the beginning on finish.
    waveSurfer.current.on('finish', () => {
      if (waveSurfer.current) {
        waveSurfer.current.seekTo(0);
      }
    });

    // Handles loading the audio file.
    const loadAudio = () => {
      if (!call.audioPath) return;
      try {
        waveSurfer.current?.load(call?.audioPath);
      } catch (error) {
        console.error('WaveSurfer load error:', error);
      }
    };

    // Load the audio after the component mounts.
    setTimeout(loadAudio, 0);

    // Cleanup on component unmount.
    return () => {
      if (waveSurfer.current) {
        try {
          waveSurfer.current.destroy();
        } catch (error) {
          console.error('Error destroying WaveSurfer instance:', error);
        }
      }
      setIsLoading(true);
      setCurrentTime(0);
      setDuration(0);
    };
  }, [call?.audioPath]);

  // Add event listener for space bar to play/pause the audio.
  useEffect(() => {
    // Prevent the media player from playing when the settings modal is open.
    if (isSettingsModalOpen) return;

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === 'Space' && waveSurfer.current) {
        waveSurfer.current.playPause();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [isSettingsModalOpen]);

  // Return null if no call is selected (ie MediaPlayer should be closed).
  if (!call) return null;

  return (
    <div
      className={clsx(
        'fixed bottom-0 left-0 right-0  transition-all duration-500 ease-in-out',
        isDrawerMounted ? 'translate-y-0' : 'translate-y-full'
      )}
      ref={ref}
    >
      {!isCallDetailsDrawerOpen && (
        <div className="flex justify-end bg-transparent">
          <ControlButtons callSid={callSid} processingStatus={call.processingStatus} />
        </div>
      )}
      <div className="relative flex items-center gap-8 border-t border-gray-200 bg-base-0 p-8">
        <CallerSection call={call} />
        <Divider type={Orientation.VERTICAL} />
        <div className="relative flex flex-grow flex-col items-center">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <Spinner size={ComponentSize.MEDIUM} />
            </div>
          )}
          <div className={clsx('flex w-full flex-col gap-2', isLoading && 'invisible')}>
            <CommentsPanel duration={duration} callSid={callSid} />
            <div
              ref={waveformRef}
              id="waveform"
              className="w-full"
              style={{ height: SINGLE_AUDIO_WAVE_HEIGHT * 2 }}
            ></div>
            <div className="flex w-full justify-between">
              <Typography className="w-10 text-xs" size={TypographySize.CAPTION}>
                {formatSecondsToDuration(currentTime)}
              </Typography>
              <Typography className="w-10 text-right text-xs" size={TypographySize.CAPTION}>
                {formatSecondsToDuration(duration)}
              </Typography>
            </div>
          </div>
          <div className={clsx('relative flex w-full items-center justify-between', isLoading && 'invisible')}>
            <div className="flex flex-col gap-1">
              {renderLegendElement(CallSpeaker.AGENT)}
              {renderLegendElement(CallSpeaker.CUSTOMER)}
            </div>
            <MediaControls waveSurfer={waveSurfer} currentTime={currentTime} duration={duration} />
            {waveSurfer.current && (
              <MediaPlayerActions
                waveSurfer={waveSurfer.current}
                call={call}
                processingStatus={call.processingStatus}
                currentTime={currentTime}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

MediaPlayer.displayName = 'MediaPlayer';
export default MediaPlayer;
