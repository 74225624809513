import { useMemo } from 'react';
import { RadioGroup, TextInput, YES_NO_OPTIONS, YesNoAnswer } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setHangupPrompt, setIsHangupFeatureEnabled } from '../../../../redux/reducers';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const EndingTheCall = () => {
  const dispatch = useAppDispatch();
  const { isHangupFeatureEnabled, hangupPrompt } = useAppSelector((state) => state.prospect.configuration);

  const fields = useMemo(
    () => [
      {
        label: 'Can hangup?',
        content: (
          <RadioGroup
            className="h-8"
            options={YES_NO_OPTIONS}
            value={isHangupFeatureEnabled ? YesNoAnswer.YES : YesNoAnswer.NO}
            onChange={(e) => {
              const newIsEnabled = e.target.value === YesNoAnswer.YES;
              dispatch(setIsHangupFeatureEnabled(newIsEnabled));
              // Reset the hangup prompt on disabling the hangup feature.
              if (!newIsEnabled) {
                dispatch(setHangupPrompt(undefined));
              }
            }}
          />
        ),
      },
      {
        label: `Hangup prompt${isHangupFeatureEnabled ? '*' : ''}`,
        content: (
          <TextInput
            disabled={!isHangupFeatureEnabled} // Changing the prompt is only allowed if hangup feature is enabled.
            value={hangupPrompt}
            onChange={(e) => dispatch(setHangupPrompt(e.target.value))}
          />
        ),
      },
    ],
    [isHangupFeatureEnabled, hangupPrompt]
  );

  return (
    <TabSection title="Ending the call">
      {fields.map((field) => (
        <ProspectPageField key={field.label} label={field.label}>
          {field.content}
        </ProspectPageField>
      ))}
    </TabSection>
  );
};

export default EndingTheCall;
