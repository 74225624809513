import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector, useHandleApiResponse } from '../../../hooks';
import { closeGongCallsModal } from '../../../redux/reducers';
import { useCreateGongProspectMutation, useGetGongCallsMutation } from '../../../services';
import { ComponentSize, TextColor, DateOptions, DateFormat, TimeFormat, GongCall } from '../../../types';
import {
  DataTable,
  DataTableColumnDef,
  Dialog,
  TextButton,
  Typography,
  TypographySize,
  SelectOption,
  Checkbox,
} from '../../shared';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatSecondsToDuration, getStartAndEndDatesFromDateOption } from '../../../utils';
import GongCallsFilters from './GongCallsFilters';
import GongSuccessModal from './GongSuccessModal';
import CallInfoCell from './CallInfoCell';

const GONG_MODAL_WIDTH = 900;

const ERROR_MSG = 'Failed to create prospects';

const GongCallsModal = () => {
  // Redux
  const dispatch = useAppDispatch();
  const isGongCallsModalOpen = useAppSelector((state) => state.modal.isGongCallsModalOpen);

  // Custom hooks
  const handleApiResponse = useHandleApiResponse();

  // State
  const [selectedParticipants, setSelectedParticipants] = useState<SelectOption[]>([]);
  const [selectedDate, setSelectedDate] = useState<SelectOption | undefined>(undefined);

  const [selectedCallIds, setSelectedCallIds] = useState<string[]>([]);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  // Mutations
  const [getGongCalls, { data: gongCalls = { calls: [], pagination: {} }, isLoading: isLoadingGongCalls }] =
    useGetGongCallsMutation();
  const [createGongProspect, { isLoading: isCreatingProspects }] = useCreateGongProspectMutation();

  const calls = gongCalls.calls || [];

  // Prospects label to account for pluralization
  const prospectsLabel = useMemo(() => {
    // Only show count if there are selected calls, otherwise show "prospects"
    return selectedCallIds.length > 0
      ? `${selectedCallIds.length} prospect${selectedCallIds.length !== 1 ? 's' : ''}`
      : 'prospects';
  }, [selectedCallIds]);

  // Filters to pass to the getGongCalls mutation
  const filters = useMemo(
    () => ({
      userIds: selectedParticipants.length > 0 ? selectedParticipants.map((p) => p.value) : undefined,
      startTime: selectedDate ? getStartAndEndDatesFromDateOption(selectedDate.value as DateOptions) : undefined,
    }),
    [selectedParticipants, selectedDate, getStartAndEndDatesFromDateOption]
  );

  // Indices of the active rows according to the selected calls IDs
  const activeRowIndices = useMemo(
    () => calls.map((call, index) => (selectedCallIds.includes(call.id) ? index : -1)).filter((index) => index !== -1),
    [calls, selectedCallIds]
  );

  // Get the Gong calls
  useEffect(() => {
    if (!isGongCallsModalOpen) return;
    getGongCalls(filters);
  }, [getGongCalls, filters, isGongCallsModalOpen]);

  // Reset state when modal closes
  useEffect(() => {
    if (!isGongCallsModalOpen) {
      setSelectedCallIds([]);
      setSelectedParticipants([]);
      setSelectedDate(undefined);
    }
  }, [isGongCallsModalOpen]);

  // Toggle the selection of a call
  const toggleRowSelection = useCallback((callId: string, checked: boolean) => {
    setSelectedCallIds((prevSelected) => {
      return checked ? [...prevSelected, callId] : prevSelected.filter((id) => id !== callId);
    });
  }, []);

  const handleOnRowClick = (callIndex: number) => {
    const call = calls[callIndex];
    const isSelected = selectedCallIds.includes(call.id);
    toggleRowSelection(call.id, !isSelected);
  };

  const handleCreateProspects = useCallback(async () => {
    try {
      if (selectedCallIds.length === 0) return;

      const response = await createGongProspect({ gongCallIds: selectedCallIds });
      handleApiResponse({
        response,
        errorMsg: ERROR_MSG,
        successMsg: `Successfully created ${prospectsLabel}`,
        onSuccess: () => setIsSuccessModalOpen(true),
      });
    } catch (error) {
      console.error(error);
    }
  }, [createGongProspect, handleApiResponse, prospectsLabel, selectedCallIds]);

  // Define outside the columns definition to prevent recreation on every render
  const SelectionCheckbox = ({ checked, onChange }: { checked: boolean; onChange: (checked: boolean) => void }) => (
    <div className="flex justify-center">
      <Checkbox checked={checked} setChecked={onChange} size={ComponentSize.X_SMALL} />
    </div>
  );

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setSelectedCallIds([]);
  };

  // Define columns for the data table.
  const columns: DataTableColumnDef<GongCall>[] = useMemo(
    () => [
      {
        accessorKey: 'isSelected',
        width: '10%',
        header: () => (
          <SelectionCheckbox
            checked={calls.length > 0 && calls.every((call) => selectedCallIds.includes(call.id))}
            onChange={(checked) => setSelectedCallIds(checked ? calls.map((call) => call.id) : [])}
          />
        ),
        cell: ({ row }) => (
          <SelectionCheckbox
            checked={selectedCallIds.includes(row.original.id)}
            onChange={(checked) => toggleRowSelection(row.original.id, checked)}
          />
        ),
      },
      {
        accessorKey: 'callInfo',
        width: '40%',
        header: 'Call info',
        cell: ({ row }) => <CallInfoCell call={row.original} />,
      },
      {
        accessorKey: 'duration',
        width: '25%',
        header: 'Duration',
        cell: ({ row }) => formatSecondsToDuration(row.original.duration),
      },
      {
        accessorKey: 'callDate',
        header: 'Call date',
        width: '25%',
        cell: ({ row }) => {
          const date = row.original?.callDate;
          return (
            <div className="flex flex-col gap-1">
              <Typography color={TextColor.SECONDARY} size={TypographySize.CAPTION}>
                {dayjs(date).format(TimeFormat.SHORT_TIME)}
              </Typography>
              <Typography size={TypographySize.CAPTION}>{dayjs(date).format(DateFormat.MONTH_DAY)}</Typography>
            </div>
          );
        },
      },
    ],
    [calls, selectedCallIds, toggleRowSelection]
  );

  return (
    <Dialog
      isOpen={isGongCallsModalOpen}
      onClose={() => dispatch(closeGongCallsModal())}
      title="Create prospects"
      description="An AI twin will be built based on each selected Gong calls below."
      width={GONG_MODAL_WIDTH}
    >
      <div className="flex h-full flex-col justify-between gap-4">
        <div className="flex h-full gap-4">
          <div className="flex-1">
            <GongCallsFilters
              selectedParticipants={selectedParticipants}
              setSelectedParticipants={setSelectedParticipants}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </div>
          <DataTable
            columns={columns}
            data={calls}
            isLoading={isLoadingGongCalls}
            tableClassName="h-[480px] overflow-y-auto"
            activeRowIndices={activeRowIndices}
            onRowClick={(_e, index) => handleOnRowClick(index)}
          />
        </div>
        <div className="flex justify-end bg-white pt-4">
          <TextButton
            text={`Create ${prospectsLabel}`}
            onClick={handleCreateProspects}
            size={ComponentSize.MEDIUM}
            loading={isCreatingProspects}
            disabled={selectedCallIds.length === 0}
          />
        </div>
      </div>
      <GongSuccessModal
        isOpen={isSuccessModalOpen}
        numOfProspects={selectedCallIds.length}
        onClose={handleCloseSuccessModal}
      />
    </Dialog>
  );
};

export default GongCallsModal;
