import { useMemo } from 'react';
import { TextInput, TextInputType } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { randomizeFirstMessage, setFirstMessage, setNumOfInitialShortResponses } from '../../../../redux/reducers';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const BeginningTheCall = () => {
  const dispatch = useAppDispatch();
  const { firstMessage, numOfInitialShortResponses } = useAppSelector((state) => state.prospect.configuration);

  const fields = useMemo(
    () => [
      {
        fullWidth: true,
        label: 'First message*',
        content: (
          <TextInput
            value={firstMessage.value}
            onChange={(e) => {
              const newValue = e.target.value;
              dispatch(setFirstMessage({ value: newValue, isUserModified: !!newValue }));
            }}
          />
        ),
        onRandomize: () => dispatch(randomizeFirstMessage()),
      },
      {
        label: '# of initial short responses',
        content: (
          <TextInput
            type={TextInputType.NUMBER}
            value={numOfInitialShortResponses?.toString()}
            onChange={(e) => {
              const newValue = e.target.value ? Number(e.target.value) : undefined;
              dispatch(setNumOfInitialShortResponses(newValue));
            }}
          />
        ),
      },
    ],
    [firstMessage, numOfInitialShortResponses]
  );

  return (
    <TabSection title="Beginning the call">
      <div className="flex gap-4">
        {fields.map((field) => (
          <ProspectPageField
            fullWidth={field.fullWidth}
            key={field.label}
            label={field.label}
            onRandomize={field.onRandomize}
          >
            {field.content}
          </ProspectPageField>
        ))}
      </div>
    </TabSection>
  );
};

export default BeginningTheCall;
