import { useMemo } from 'react';
import { Select, Slider, Typography } from '../../../../components';
import {
  LLM_MAX_TOKENS_SLIDER_LIMITS,
  LLM_PROVIDER_ID_MODEL_OPTIONS,
  LLM_PROVIDER_OPTIONS,
  LLM_TEMPERATURE_SLIDER_LIMITS,
  STT_MODEL_OPTIONS,
} from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  setLlmMaxTokens,
  setLlmModel,
  setLlmModelId,
  setLlmTemperature,
  setSttModelId,
} from '../../../../redux/reducers';
import { LLMProviderID } from '../../../../types';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const TECHNICAL_CONFIG_SELECT_WIDTH = 340;

const TechnicalConfiguration = () => {
  const dispatch = useAppDispatch();
  const { sttModelId, llmModel, llmModelId, llmTemperature, llmMaxTokens } = useAppSelector(
    (state) => state.prospect.configuration
  );

  const llmModelOptions = llmModel ? LLM_PROVIDER_ID_MODEL_OPTIONS[llmModel] : [];

  const fields = useMemo(
    () => [
      [
        {
          fitWidth: true,
          label: 'Speech to text model*',
          content: (
            <Select
              options={STT_MODEL_OPTIONS}
              selected={STT_MODEL_OPTIONS.find((option) => option.value === sttModelId)}
              width={TECHNICAL_CONFIG_SELECT_WIDTH}
              onChange={(newValue) => dispatch(setSttModelId(newValue as string))}
            />
          ),
        },
      ],
      [
        {
          label: 'LLM provider*',
          content: (
            <Select
              options={LLM_PROVIDER_OPTIONS}
              selected={LLM_PROVIDER_OPTIONS.find((option) => option.value === llmModel)}
              width={TECHNICAL_CONFIG_SELECT_WIDTH}
              onChange={(newValue) => {
                dispatch(setLlmModel(newValue as LLMProviderID));
                // Reset LLM ID on changing the provider.
                dispatch(setLlmModelId(undefined));
              }}
            />
          ),
        },
        {
          label: 'LLM*',
          content: (
            <Select
              disabled={!llmModel}
              options={llmModelOptions}
              selected={llmModelOptions.find((option) => option.value === llmModelId)}
              width={TECHNICAL_CONFIG_SELECT_WIDTH}
              onChange={(newValue) => dispatch(setLlmModelId(newValue as string))}
            />
          ),
        },
      ],
      [
        {
          fullWidth: true,
          label: 'LLM temperature*',
          content: (
            <div className="flex items-center gap-4">
              <Typography className="select-none">{llmTemperature.toFixed(1)}</Typography>
              <Slider
                hideStep
                max={LLM_TEMPERATURE_SLIDER_LIMITS.max}
                min={LLM_TEMPERATURE_SLIDER_LIMITS.min}
                step={LLM_TEMPERATURE_SLIDER_LIMITS.step}
                value={llmTemperature}
                onChange={(newValue) => dispatch(setLlmTemperature(newValue))}
              />
              <Typography className="select-none">{LLM_TEMPERATURE_SLIDER_LIMITS.max.toFixed(1)}</Typography>
            </div>
          ),
        },
        {
          fullWidth: true,
          label: 'LLM max tokens*',
          content: (
            <div className="flex items-center gap-4">
              <Typography className="select-none">{llmMaxTokens}</Typography>
              <Slider
                hideStep
                max={LLM_MAX_TOKENS_SLIDER_LIMITS.max}
                min={LLM_MAX_TOKENS_SLIDER_LIMITS.min}
                step={LLM_MAX_TOKENS_SLIDER_LIMITS.step}
                value={llmMaxTokens}
                onChange={(newValue) => dispatch(setLlmMaxTokens(newValue))}
              />
              <Typography className="select-none">{LLM_MAX_TOKENS_SLIDER_LIMITS.max}</Typography>
            </div>
          ),
        },
      ],
      [
        {
          fitWidth: true,
          label: 'Prompt template*',
          content: (
            <Select selected={undefined} options={[]} width={TECHNICAL_CONFIG_SELECT_WIDTH} onChange={() => {}} />
          ),
        },
      ],
    ],
    [llmModel, llmModelId, llmTemperature, llmMaxTokens, sttModelId]
  );

  return (
    <TabSection title="Technical configuration">
      {fields.map((fieldGroup, index) => (
        <div key={index} className="flex gap-4">
          {fieldGroup.map((field, index) => (
            <ProspectPageField key={index} {...field}>
              {field.content}
            </ProspectPageField>
          ))}
        </div>
      ))}
    </TabSection>
  );
};

export default TechnicalConfiguration;
