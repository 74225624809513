import { Dispatch, SetStateAction } from 'react';
import { ButtonColor, ButtonGroup, ButtonVariant, Icon, Tab, TextButton } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  randomizeAccount,
  randomizeDemographics,
  randomizeFirstMessage,
  randomizeLead,
  randomizeObjections,
  randomizeOutcome,
  randomizePersonality,
  randomizePriorities,
  randomizeQuestions,
  randomizeScene,
  randomizeVoice,
} from '../../../redux/reducers';
import { ProspectPageMode, ProspectPageTabs } from '../../../types';
import { areRequiredFieldsFilled } from '../../../utils';

interface CreateFooterProps {
  activeTabIndex: number;
  tabs: Tab[];
  setActiveTab: Dispatch<SetStateAction<ProspectPageTabs>>;
  setMode: Dispatch<SetStateAction<ProspectPageMode>>;
  setVisitedTabs: Dispatch<SetStateAction<Set<ProspectPageTabs>>>;
}

const CreateFooter = ({ activeTabIndex, tabs, setActiveTab, setMode, setVisitedTabs }: CreateFooterProps) => {
  const dispatch = useAppDispatch();

  const activeTab = tabs[activeTabIndex].id as ProspectPageTabs;
  const isNextTabDisabled = useAppSelector((state) => !areRequiredFieldsFilled(state, activeTab));

  const isLastTab = activeTabIndex === tabs.length - 1;
  const nextTab = isLastTab ? null : (tabs[activeTabIndex + 1].id as ProspectPageTabs);

  const handleNextSection = () => {
    if (!nextTab) return;
    setActiveTab(nextTab);
    setVisitedTabs((prev) => new Set(prev).add(nextTab));
  };

  // TODO: Create prospect and generate prompt.
  const handleCreateProspect = () => {
    // If prospect creation is successful,
    // we go to the Prompt tab and switch to view mode.
    handleNextSection();
    setMode(ProspectPageMode.VIEW);
  };

  // Fills the prospect form section with random values.
  const handleFillForMe = () => {
    switch (activeTab) {
      case ProspectPageTabs.PERSONAL:
        dispatch(randomizeDemographics());
        dispatch(randomizePersonality());
        dispatch(randomizeVoice());
        break;
      case ProspectPageTabs.LEAD:
        dispatch(randomizeLead());
        break;
      case ProspectPageTabs.ACCOUNT:
        dispatch(randomizeAccount());
        break;
      case ProspectPageTabs.SCENARIO:
        dispatch(randomizePriorities());
        dispatch(randomizeObjections());
        dispatch(randomizeQuestions());
        dispatch(randomizeOutcome());
        dispatch(randomizeScene());
        break;
      case ProspectPageTabs.CONFIGURATION:
        dispatch(randomizeFirstMessage());
        break;
      default:
        break;
    }
  };

  // If there is no next tab, we should not render anything.
  if (!nextTab) return null;

  return (
    <ButtonGroup>
      <TextButton
        color={ButtonColor.PRIMARY}
        text={nextTab === ProspectPageTabs.PROMPT ? 'Generate prompt' : 'Next section'}
        onClick={nextTab === ProspectPageTabs.PROMPT ? handleCreateProspect : handleNextSection}
        disabled={isNextTabDisabled}
        tooltip={isNextTabDisabled ? 'Please fill in the required fields.' : undefined}
      />
      <TextButton
        startIcon={Icon.WAND}
        color={ButtonColor.AI}
        text="Fill for me"
        onClick={handleFillForMe}
        variant={ButtonVariant.OUTLINE}
      />
    </ButtonGroup>
  );
};

export default CreateFooter;
