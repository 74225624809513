import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableAction, Icon } from '../../components';
import { AppRoutes } from '../../constants';
import { useAppDispatch, useAppSelector, useShareCall } from '../../hooks';
import { openRequestReviewModal, openUnflagConfirmModal, setCurrentCallDetails } from '../../redux/reducers';
import { Call, CallProcessingStatus, Permissions, StatusColor } from '../../types';
import { conditionalArray } from '../../utils';

/**
 * Custom hook to generate actions for call rows in the data table.
 */
const useCallActions = (onClose: () => void, clickedCall?: Call): DataTableAction[] => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { onShare } = useShareCall(clickedCall?.callSid, undefined, onClose);

  const user = useAppSelector((state) => state.auth.user);
  const permissions = user?.permissions || [];
  const isSuperAdmin = permissions.includes(Permissions.ADMIN_ACCESS);

  // Check if the user can flag calls.
  const canFlag = permissions.includes(Permissions.FLAG_CALL);

  // Check if the user can unflag the call.
  const canUnflag = !canFlag && permissions.includes(Permissions.UNFLAG_CALL) && !!clickedCall?.isFlaggedToUser;

  // Handles opening the media player to the specified call.
  const onListen = useCallback(
    (call: Call) => {
      dispatch(setCurrentCallDetails({ callSid: call.callSid, autoPlay: true }));
    },
    [dispatch]
  );

  // Handles opening the request review modal.
  const onRequestReview = useCallback(
    (call: Call) => {
      dispatch(openRequestReviewModal({ call }));
    },
    [dispatch]
  );

  // Handles opening the call details page of the selected call
  // by navigating to the call history page with the callSid as a parameter
  // while preserving existing search params.
  const onOpen = useCallback(
    (call: Call) =>
      navigate({
        pathname: `${AppRoutes.CALL_HISTORY}/${call.callSid}`,
        search: window.location.search,
      }),
    [navigate, dispatch]
  );

  // Handles opening the unflag confirmation modal.
  const onUnflagCall = useCallback(
    (call: Call) => {
      dispatch(openUnflagConfirmModal(call.callSid));
    },
    [dispatch]
  );

  // Handles opening the practice page filtered to the selected prospect.
  const onViewProspect = useCallback(
    (call: Call) => {
      const personaId = call.practiceProspect.personaId;
      if (isSuperAdmin) {
        // For super admins, we can navigate directly to the prospect page.
        navigate(`${AppRoutes.PROSPECT}/${personaId}`);
      } else {
        // For non-super admins, we navigate to the practice page and filter by prospect.
        const searchParams = new URLSearchParams({ prospect: personaId });
        navigate({ pathname: AppRoutes.PRACTICE, search: searchParams.toString() });
      }
    },
    [isSuperAdmin, navigate]
  );

  // Handles performing the provided call action
  // by calling the action with the clicked call if it exists and then closing the modal.
  const handleCallAction = useCallback(
    (action: (call: Call) => void) => {
      if (!clickedCall) return;
      action(clickedCall);
      onClose();
    },
    [clickedCall, onClose]
  );

  return [
    // Show 'Listen' action only if the call's audioPath exists.
    ...conditionalArray(!!clickedCall?.audioPath, {
      label: 'Listen',
      icon: Icon.PLAY,
      onClick: () => handleCallAction(onListen),
    }),
    // Show 'Processing...' if the call is still undergoing processing.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSING, {
      label: 'Processing...',
      icon: Icon.OPEN,
    }),
    // Show 'Processing error' if processing has failed.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSING_FAILED, {
      label: 'Processing error',
      icon: Icon.OPEN,
    }),
    // Show 'Open' action if processing has succeeded.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSED, {
      label: 'Call details',
      icon: Icon.OPEN,
      onClick: () => handleCallAction(onOpen),
    }),
    {
      // 'View prospect' action.
      label: 'View prospect',
      icon: Icon.USER_SEARCH,
      onClick: () => handleCallAction(onViewProspect),
    },
    // Show 'Request review' action if the user can flag calls.
    ...conditionalArray(canFlag, {
      label: 'Request review',
      icon: Icon.FLAG,
      iconFill: true,
      onClick: () => handleCallAction(onRequestReview),
    }),
    // Show 'Unflag call' action if the user has permission to unflag calls and the call is flagged.
    ...conditionalArray(canUnflag, {
      label: 'Unflag call',
      icon: Icon.FLAG,
      iconColor: StatusColor.WARNING,
      iconFill: true,
      onClick: () => handleCallAction(onUnflagCall),
    }),
    // Show 'Share' action if processing has succeeded.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSED, {
      label: 'Share',
      icon: Icon.SHARE,
      successMsg: 'Link to call copied to clipboard',
      onClick: () => handleCallAction(onShare),
    }),
  ];
};

export default useCallActions;
