import { v4 as uuidv4 } from 'uuid';
import { ScorecardQuestionType, ScorecardSectionType } from '../types';

export const INITIAL_QUESTION: ScorecardQuestionType = {
  id: uuidv4(),
  text: '',
};

export const INITIAL_SECTION: ScorecardSectionType = {
  id: uuidv4(),
  title: '',
  questions: [INITIAL_QUESTION],
};

// Initial sections array with at least one section and at least one question
export const INITIAL_SECTIONS: ScorecardSectionType[] = [INITIAL_SECTION];
