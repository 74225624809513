import { AlertType, TextInput, Typography } from '../../../../shared';
import { useCallback, useState } from 'react';
import { useSubmitGongAPIKeyMutation } from '../../../../../services';
import { isValidURL } from '../../../../../utils';
import { useAppDispatch, useAppSelector, useHandleApiResponse, useToast } from '../../../../../hooks';
import { GongAuthMethod, TextColor } from '../../../../../types';
import IntegrationSectionHeader from './IntegrationSectionHeader';
import ConnectButton from './ConnectButton';
import { setOrganization } from '../../../../../redux/reducers';

const ERROR_MSG = 'Connection to Gong failed';

const ConnectGongAPIKey = () => {
  const [accessKey, setAccessKey] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [apiBaseUrl, setApiBaseUrl] = useState('');

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isFailedConnection, setIsFailedConnection] = useState(false);

  const { organization } = useAppSelector((state) => state.auth) || {};
  const isGongConnected = !!organization?.gongAuth;
  const dispatch = useAppDispatch();
  const [submitGongAPIKey, { isLoading: isSubmitting }] = useSubmitGongAPIKeyMutation();

  const handleApiResponse = useHandleApiResponse();
  const { showToast } = useToast();

  const resetInputs = useCallback(() => {
    setAccessKey('');
    setSecretKey('');
    setApiBaseUrl('');
  }, []);

  // Handle the error response
  const onError = useCallback(() => {
    setIsFailedConnection(true);
    showToast({ message: ERROR_MSG, type: AlertType.ERROR });
  }, [showToast]);

  // Handle the success response
  const onSuccess = useCallback(() => {
    setIsFailedConnection(false);
    showToast({ message: 'Gong connected successfully', type: AlertType.SUCCESS });

    if (!organization) return;

    dispatch(setOrganization({ ...organization, gongAuth: { expiresAt: '', authMethod: GongAuthMethod.API_KEY } }));
    resetInputs();
  }, [dispatch, organization, showToast, resetInputs]);

  // Validate the inputs
  const validateInputs = useCallback(() => {
    const newErrors: Record<string, string> = {
      accessKey: !accessKey ? 'Please enter an access key' : '',
      secretKey: !secretKey ? 'Please enter a secret key' : '',
      apiBaseUrl: !apiBaseUrl ? 'Please enter a URL' : !isValidURL(apiBaseUrl) ? 'Please enter a valid URL' : '',
    };

    // Remove empty error messages
    const filteredErrors = Object.fromEntries(Object.entries(newErrors).filter(([, value]) => value !== ''));

    setErrors(filteredErrors);
    return Object.keys(filteredErrors).length === 0;
  }, [accessKey, apiBaseUrl, secretKey]);

  // Handle the API key submission
  const handleSubmitGongAPIKey = useCallback(async () => {
    // Validate the inputs
    if (!validateInputs()) return;

    try {
      const response = await submitGongAPIKey({ accessKey, secretKey, apiBaseUrl });
      handleApiResponse({ response, errorMsg: ERROR_MSG, onError, onSuccess });
    } catch (error) {
      onError();
    }
  }, [submitGongAPIKey, handleApiResponse, onError, onSuccess, validateInputs]);

  // Handle the access key change
  const handleOnAccessKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Reset the error
    setErrors({ ...errors, accessKey: '' });
    setAccessKey(e.target.value);
  };

  // Handle the secret key change
  const handleOnSecretKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Reset the error
    setErrors({ ...errors, secretKey: '' });
    setSecretKey(e.target.value);
  };

  // Handle the API base URL change
  const handleOnApiBaseUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Reset the error
    setErrors({ ...errors, apiBaseUrl: '' });
    setApiBaseUrl(e.target.value);
  };

  return (
    <div className="flex flex-col gap-4">
      <IntegrationSectionHeader
        title="Gong API Key"
        description={
          <>
            If OAuth is not available, you can provide an API access key instead. See Gong instructions&nbsp;
            <a
              className="underline"
              href="https://help.gong.io/docs/receive-access-to-the-api"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </>
        }
      />
      <div className="flex flex-col gap-1">
        <div className="flex flex-col gap-2">
          <Typography color={TextColor.SECONDARY}>Username / Access Key</Typography>
          <TextInput
            value={accessKey}
            onChange={handleOnAccessKeyChange}
            error={errors.accessKey ?? ''}
            disabled={isGongConnected}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Typography color={TextColor.SECONDARY}>Password / Access Key Secret</Typography>
          <TextInput
            value={secretKey}
            onChange={handleOnSecretKeyChange}
            error={errors.secretKey ?? ''}
            disabled={isGongConnected}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Typography color={TextColor.SECONDARY}>API Base URL</Typography>
          <TextInput
            value={apiBaseUrl}
            onChange={handleOnApiBaseUrlChange}
            error={errors.apiBaseUrl ?? ''}
            disabled={isGongConnected}
          />
        </div>
      </div>
      <div className="-mt-2">
        <ConnectButton
          onConnect={handleSubmitGongAPIKey}
          isLoading={isSubmitting}
          isFailedConnection={isFailedConnection}
          gongAuthMethod={GongAuthMethod.API_KEY}
        />
      </div>
    </div>
  );
};

export default ConnectGongAPIKey;
