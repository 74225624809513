import { useMemo } from 'react';
import { Select } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setNumOfRequiredUncoveredPriorities, setObjectionRate, setQuestionRate } from '../../../../redux/reducers';
import { SeverityLevel } from '../../../../types';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const SEVERITY_LEVEL_OPTIONS = Object.values(SeverityLevel).map((level) => ({ label: level, value: level }));

const ConversationTuning = () => {
  const dispatch = useAppDispatch();
  const {
    scenario: { priorities },
    configuration: { objectionRate, questionRate, numOfRequiredUncoveredPriorities: numOfPriorities },
  } = useAppSelector((state) => state.prospect);

  // +1 because we want to include the option to select 0 uncovered priorities.
  const numOfPrioritiesOptions = Array.from({ length: priorities.length + 1 }, (_, index) => ({
    label: index.toString(),
    value: index.toString(),
  }));

  // All fields are clearable bec they are not required.
  const fields = useMemo(
    () => [
      {
        label: 'Objection rate',
        content: (
          <Select
            clearable
            options={SEVERITY_LEVEL_OPTIONS}
            selected={SEVERITY_LEVEL_OPTIONS.find((option) => option.value === objectionRate)}
            onChange={(newValue) => dispatch(setObjectionRate(newValue as SeverityLevel))}
          />
        ),
      },
      {
        label: 'Question rate',
        content: (
          <Select
            clearable
            options={SEVERITY_LEVEL_OPTIONS}
            selected={SEVERITY_LEVEL_OPTIONS.find((option) => option.value === questionRate)}
            onChange={(newValue) => dispatch(setQuestionRate(newValue as SeverityLevel))}
          />
        ),
      },
      {
        label: 'Required uncovered priorities',
        content: (
          <Select
            clearable
            options={numOfPrioritiesOptions}
            selected={numOfPrioritiesOptions.find((option) => option.value === numOfPriorities?.toString())}
            onChange={(newValue) => {
              const newNumOfPriorities = newValue === undefined ? undefined : Number(newValue);
              dispatch(setNumOfRequiredUncoveredPriorities(newNumOfPriorities));
            }}
          />
        ),
      },
    ],
    [objectionRate, questionRate, numOfPriorities]
  );

  return (
    <TabSection title="Conversation tuning">
      <div className="flex gap-4">
        {fields.map((field) => (
          <ProspectPageField key={field.label} label={field.label} fullWidth>
            {field.content}
          </ProspectPageField>
        ))}
      </div>
    </TabSection>
  );
};

export default ConversationTuning;
