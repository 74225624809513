import clsx from 'clsx';
import React, { forwardRef, useCallback, useEffect } from 'react';
import { ComponentSize, TextColor } from '../../../types';
import { Icon, IconComponent, Icons } from '../Icons';
import { Typography, TypographySize } from '../Typography';
import { DropdownItemProps } from './DropdownItem.types';

const DropdownItem = forwardRef<HTMLDivElement, DropdownItemProps>(
  ({ label, active, destructive, disabled, endElement, hovered, icon: iconProp, startElement, onClick }, ref) => {
    const shouldDisable = disabled || !onClick;

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (onClick) {
        e.stopPropagation();
        onClick();
      }
    };

    const getTextColor = useCallback(() => {
      if (shouldDisable) return TextColor.TERTIARY;
      if (destructive) return TextColor.DESTRUCTIVE;
      return TextColor.SECONDARY;
    }, [destructive, shouldDisable]);

    const renderIcon = useCallback(
      (icon: Icon | IconComponent) => {
        const iconSize = ComponentSize.X_SMALL;
        return typeof icon === 'string' ? (
          <Icons icon={icon} color={getTextColor()} size={iconSize} />
        ) : (
          React.cloneElement(icon, {
            color: icon.props.color ?? getTextColor(),
            size: icon.props.size ?? iconSize,
          })
        );
      },
      [getTextColor]
    );

    const renderLabel = useCallback(() => {
      const textSize = TypographySize.CAPTION;
      return typeof label === 'string' ? (
        <Typography color={getTextColor()} size={textSize}>
          {label}
        </Typography>
      ) : (
        React.cloneElement(label, {
          color: label.props.color ?? getTextColor(),
          size: label.props.size ?? textSize,
        })
      );
    }, [getTextColor, label]);

    // Handles triggering the onClick event when the Enter key is pressed and the item is hovered.
    useEffect(() => {
      if (!hovered || !onClick) return;

      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          onClick();
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      return () => document.removeEventListener('keydown', handleKeyDown);
    }, [onClick, hovered]);

    return (
      <div
        className={clsx(
          'flex cursor-pointer justify-between rounded-lg p-2',
          hovered && (destructive ? 'bg-error' : 'bg-base-100'), // For controlled hover state.
          destructive ? 'hover:bg-error' : 'hover:bg-base-100', // For uncontrolled hover state.
          shouldDisable && 'pointer-events-none'
        )}
        onClick={handleClick}
        ref={ref}
      >
        <div className="flex items-center gap-2">
          {startElement && startElement}
          {iconProp && renderIcon(iconProp)}
          {renderLabel()}
        </div>
        <div className="flex items-center gap-2">
          {endElement && endElement}
          {active && renderIcon(Icon.CHECK)}
        </div>
      </div>
    );
  }
);

DropdownItem.displayName = 'DropdownItem';

export default DropdownItem;
