import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonColor, ButtonVariant, Icon, ProspectDesignerModal, Tabs, TabsType, TextButton } from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { resetProspectForm } from '../../redux/reducers';
import { ProspectPageMode, ProspectPageTabs } from '../../types';
import { areRequiredFieldsFilled, camelCaseToLabel } from '../../utils';
import ProspectPageFooter from './ProspectPageFooter';
import { AccountTab, ConfigurationTab, LeadTab, PersonalTab, ScenarioTab } from './ProspectPageTabs';

const DEFAULT_ACTIVE_TAB = ProspectPageTabs.PERSONAL;

const ProspectPage = () => {
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);
  const [mode, setMode] = useState(ProspectPageMode.CREATE);
  const [visitedTabs, setVisitedTabs] = useState(new Set<ProspectPageTabs>([DEFAULT_ACTIVE_TAB]));

  const navigate = useNavigate();
  const { personaId } = useParams();

  const dispatch = useAppDispatch();
  const {
    orgId,
    configuration: { prospectType },
  } = useAppSelector((state) => state.prospect);

  const activeTabIndex = Object.values(ProspectPageTabs).indexOf(activeTab);
  const isNextTabEnabled = useAppSelector((state) => areRequiredFieldsFilled(state, activeTab));

  // Maps the tabs to an array of objects with the tab id, title, and disabled state.
  // In create mode, we enable tabs that have either been previously visited
  // or the next tab if all required fields in the current tab are filled.
  const tabs = Object.values(ProspectPageTabs).map((tab, index) => {
    const isNextTab = index === activeTabIndex + 1;
    const isDisabled = mode === ProspectPageMode.CREATE && !visitedTabs.has(tab) && !(isNextTab && isNextTabEnabled);
    return { id: tab, title: camelCaseToLabel(tab), disabled: isDisabled };
  });

  // Navigates back to the previous page.
  // Takes into consideration if the previous page was not the Practice page (ie. Call History page).
  const handleBack = () => {
    navigate(-1);
  };

  // Enters edit mode.
  const handleEdit = () => {
    setMode(ProspectPageMode.EDIT);
    // If the user is on the prompt tab, we bring them back to the first tab.
    if (activeTab === ProspectPageTabs.PROMPT) {
      setActiveTab(DEFAULT_ACTIVE_TAB);
    }
  };

  // Changes the active tab and updates the visited tabs.
  const handleChangeTab = (newActiveTab: string) => {
    setActiveTab(newActiveTab as ProspectPageTabs);
    setVisitedTabs((prev) => new Set(prev).add(newActiveTab as ProspectPageTabs));
  };

  // If the personaId param is present, we are viewing an existing prospect.
  useEffect(() => {
    if (personaId) {
      setMode(ProspectPageMode.VIEW);
    }
  }, [personaId]);

  // Resets the prospect form when the page is unmounted.
  useEffect(() => {
    return () => {
      dispatch(resetProspectForm());
    };
  }, []);

  const renderTabContent = () => {
    if (mode === ProspectPageMode.CREATE) {
      if (activeTab === ProspectPageTabs.PERSONAL) {
        return <PersonalTab />;
      } else if (activeTab === ProspectPageTabs.LEAD) {
        return <LeadTab />;
      } else if (activeTab === ProspectPageTabs.ACCOUNT) {
        return <AccountTab />;
      } else if (activeTab === ProspectPageTabs.SCENARIO) {
        return <ScenarioTab />;
      } else if (activeTab === ProspectPageTabs.CONFIGURATION) {
        return <ConfigurationTab />;
      } else {
        return <div>In create</div>;
      }
    } else if (mode === ProspectPageMode.EDIT) {
      return <div>In edit mode</div>;
    } else if (mode === ProspectPageMode.VIEW) {
      return <div>In view mode</div>;
    }
  };

  return (
    <div className="flex h-full flex-col">
      <div className="flex w-full flex-none items-center justify-between gap-4 px-8 pt-4">
        <div className="flex items-center gap-4">
          <TextButton onClick={handleBack} text="Back" color={ButtonColor.SECONDARY} startIcon={Icon.CHEVRON_LEFT} />
          <Tabs activeTab={activeTab} setActiveTab={handleChangeTab} type={TabsType.BORDERED} tabs={tabs} />
        </div>
        {/* Only show the edit button if the user is in view mode and on the prompt tab. */}
        {mode === ProspectPageMode.VIEW && (
          <TextButton text="Edit" variant={ButtonVariant.OUTLINE} color={ButtonColor.SECONDARY} onClick={handleEdit} />
        )}
      </div>
      <div className="flex flex-grow justify-center overflow-y-auto py-8">
        <div className="h-fit w-full max-w-[700px]">{renderTabContent()}</div>
      </div>
      <ProspectPageFooter
        activeTabIndex={activeTabIndex}
        mode={mode}
        tabs={tabs}
        setActiveTab={setActiveTab}
        setMode={setMode}
        setVisitedTabs={setVisitedTabs}
      />
      {/* Show the prospect designer modal if the org id or prospect type is not set,
      meaning the user has just started creating a prospect. */}
      {(!orgId || !prospectType) && <ProspectDesignerModal />}
    </div>
  );
};

export default ProspectPage;
