import BeginningTheCall from './BeginningTheCall';
import CallType from './CallType';
import ConversationTuning from './ConversationTuning';
import EndingTheCall from './EndingTheCall';
import TechnicalConfiguration from './TechnicalConfiguration';

const ConfigurationTab = () => {
  return (
    <div className="flex flex-col gap-4">
      <CallType />
      <BeginningTheCall />
      <ConversationTuning />
      <EndingTheCall />
      <TechnicalConfiguration />
    </div>
  );
};

export default ConfigurationTab;
