import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import fullyRampedLogo from '../../assets/logo-light.png';
import {
  Avatar,
  ButtonColor,
  ButtonVariant,
  Dialog,
  PhoneNumberInput,
  TextButton,
  TextInput,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../components';
import { PhoneVerificationModal } from '../../components/modals';
import { AppRoutes, CANCELLED_VERIFICATION_ERROR_MSG, US_COUNTRY_CODE } from '../../constants';
import { useAppDispatch, useAppSelector, useHandleApiResponse, useVerifyCallActions } from '../../hooks';
import { setIsOnboarded } from '../../redux/reducers';
import { useFinishSetupMutation, useOnboardUserMutation } from '../../services';
import { Alignment, ComponentSize, InitiateCallResponseData, TextColor } from '../../types';
import { isValidPhoneNumber, splitName } from '../../utils';
import AccountSetupLoading from './AccountSetupLoading';

const FINISH_SETUP_ERROR_MSG = 'Failed to finish setup';
const ONBOARD_USER_ERROR_MSG = 'Failed to onboard user';

const FinishSetupPage = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  // Redux
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  // State for form fields and their errors.
  const [callData, setCallData] = useState<InitiateCallResponseData | undefined>(undefined);
  const [callEnded, setCallEnded] = useState(false);
  const [countryCode, setCountryCode] = useState(US_COUNTRY_CODE);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState<string | undefined>(undefined);

  // Mutations
  const [onboardUser, { isLoading: isOnboarding }] = useOnboardUserMutation();
  const [finishSetup, { isLoading: isFinishingSetup }] = useFinishSetupMutation();

  // Custom hooks
  const handleApiResponse = useHandleApiResponse(true);
  const { initiateCall, isLoading: isVerifying } = useVerifyCallActions();

  // Controls opening/closing the verification modal depending on the verification code.
  const isVerificationModalOpen = useMemo(() => !!callData, [callData]);

  if (!user?.id) return null;

  // Splits the user's name into first and last name.
  const [firstName, lastName] = splitName(user.name);
  // The full phone number is the country code plus the phone number.
  const fullPhoneNumber = countryCode + phoneNumber;
  // Enables the verify button if the phone number is valid and there is no error.
  const isVerifyEnabled = isValidPhoneNumber(fullPhoneNumber) && !phoneNumberError;

  const callFinishSetup = useCallback(async () => {
    try {
      await finishSetup();
    } catch (error) {
      console.error(FINISH_SETUP_ERROR_MSG, error);
    }
  }, [finishSetup]);

  // Calls the finish setup mutation on mount.
  useEffect(() => {
    callFinishSetup();
  }, []);

  // Resets the phone number error and call ended state.
  const resetError = useCallback(() => {
    setPhoneNumberError('');
    setCallEnded(false);
  }, [setPhoneNumberError, setCallEnded]);

  // Starts the phone verification process.
  const onVerify = useCallback(async () => {
    resetError();
    const response = await initiateCall(countryCode, phoneNumber);
    if (response.data) {
      setCallData(response.data);
    } else {
      setPhoneNumberError(response.error);
    }
  }, [countryCode, phoneNumber, initiateCall, resetError, setCallData, setPhoneNumberError]);

  const handleOnboardUser = useCallback(async () => {
    try {
      const response = await onboardUser(user.id);

      // On error, closes the verification modal by resetting the verification code.
      const onError = () => {
        setCallData(undefined);
      };

      // On success, sets the user as onboarded and navigates to the practice page.
      const onSuccess = () => {
        dispatch(setIsOnboarded(true));
        navigate(AppRoutes.PRACTICE);
      };

      handleApiResponse({
        response,
        errorMsg: ONBOARD_USER_ERROR_MSG,
        onError,
        onSuccess,
      });
    } catch (error) {
      console.error(`${ONBOARD_USER_ERROR_MSG}: `, error);
    }
  }, [user.id, onboardUser, dispatch, navigate, setCallData]);

  // If the finish setup mutation is loading which means the user is being created, show the loading component.
  if (isFinishingSetup) {
    return <AccountSetupLoading />;
  }

  return (
    <div className="h-screen bg-base-200">
      <Dialog isOpen={!isVerificationModalOpen}>
        <div className="flex flex-col items-center gap-4 p-4">
          <div className="flex flex-col items-center gap-6">
            <img src={fullyRampedLogo} alt="FullyRamped Logo" className="h-14 w-14" />
            <Typography size={TypographySize.H2} weight={TypographyWeight.SEMI_BOLD}>
              Finish setting up your account
            </Typography>
          </div>
          <div className="mb-4 flex w-full items-center gap-4">
            <Avatar size={ComponentSize.LARGE} label={user.name} imgSrc={user.picture} />
            <div className="flex flex-grow gap-2">
              <TextInput
                size={ComponentSize.MEDIUM}
                className="flex-grow"
                value={firstName}
                disabled
                placeholder="First name"
              />
              <TextInput
                size={ComponentSize.MEDIUM}
                className="flex-grow"
                value={lastName}
                disabled
                placeholder="Last name"
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-4">
            <Typography alignment={Alignment.CENTER} size={TypographySize.H5}>
              Add the primary phone number you will use to make practice calls. You can add more later.
            </Typography>
            <PhoneNumberInput
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              size={ComponentSize.MEDIUM}
              error={phoneNumberError}
              message={callEnded ? CANCELLED_VERIFICATION_ERROR_MSG : undefined}
              resetError={resetError}
              isLoading={isVerifying || isOnboarding}
              onKeyDown={(e) => {
                // Pressing enter while the phone number is valid will start the verification process.
                if (e.key === 'Enter' && isVerifyEnabled) {
                  onVerify();
                }
              }}
            />
          </div>
          <div className="flex w-full flex-col items-end gap-4">
            <div className="flex w-full flex-col gap-2">
              <TextButton
                text="Verify"
                fullWidth
                onClick={onVerify}
                size={ComponentSize.MEDIUM}
                loading={isVerifying}
                disabled={isOnboarding || !isVerifyEnabled}
              />
              <TextButton
                text="Skip for now"
                fullWidth
                onClick={handleOnboardUser}
                size={ComponentSize.MEDIUM}
                variant={ButtonVariant.OUTLINE}
                color={ButtonColor.SECONDARY}
                disabled={isVerifying}
                loading={isOnboarding}
              />
            </div>
            <Typography
              color={TextColor.LINK}
              onClick={!isVerifying && !isOnboarding ? () => logout() : undefined}
              underline
            >
              Logout
            </Typography>
          </div>
        </div>
      </Dialog>
      <PhoneVerificationModal
        callData={callData}
        setError={setPhoneNumberError}
        userId={user.id}
        phoneNumber={fullPhoneNumber}
        onSuccess={handleOnboardUser}
        showLogo
        setCallEnded={setCallEnded}
        closeModal={() => setCallData(undefined)}
      />
    </div>
  );
};

export default FinishSetupPage;
