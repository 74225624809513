import { ChangeEvent } from 'react';

export interface RadioOption {
  value: string;
  label: string;
}

export interface RadioGroupProps {
  /** Array of radio options */
  options: RadioOption[];
  /** The currently selected value */
  value: string;
  /** Callback function to handle changes in selection */
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /** Additional class name for the radio group */
  className?: string;
}

/** Representation of a yes or no answer */
export enum YesNoAnswer {
  YES = 'YES',
  NO = 'NO',
}
