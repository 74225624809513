import React, { useCallback } from 'react';
import { TextColor } from '../../../../types';
import { BadgeDot } from '../../BadgeDot';
import { Checkbox } from '../../Checkbox';
import { Divider } from '../../Divider';
import { DropdownItem } from '../../DropdownItem';
import { Icons } from '../../Icons';
import { MULTI_SELECT_CHECKBOX_SIZE, SELECT_ICON_SIZE } from './BaseSelect.constants';
import { SelectOptionWithActive, SelectSize } from './BaseSelect.types';

interface BaseSelectOptionProps {
  index: number;
  option: SelectOptionWithActive;
  size: SelectSize;
  runOptionClick: (value: string) => void;
  hovered?: boolean;
  listRef?: React.MutableRefObject<HTMLDivElement[]>;
  multiSelect?: boolean;
}

const BaseSelectOption = ({
  index,
  option,
  size,
  runOptionClick,
  hovered,
  listRef,
  multiSelect,
}: BaseSelectOptionProps) => {
  const isCustomAction = !!option.onClick;
  // Only show divider if it's not the first option on the list.
  const showDivider = option.divider && index > 0;

  const assignRef = useCallback(
    (index: number) => (node: HTMLDivElement | null) => {
      if (node && listRef) {
        listRef.current[index] = node;
      }
    },
    [] // Initialize once on mount.
  );

  const renderCheckbox = () => (
    <Checkbox
      size={MULTI_SELECT_CHECKBOX_SIZE}
      checked={!!option.active}
      setChecked={() => runOptionClick(option.value)}
    />
  );

  const renderStartElement = () => (
    <>
      {multiSelect && !isCustomAction && renderCheckbox()}
      {option.icon && <Icons icon={option.icon} size={SELECT_ICON_SIZE[size]} color={TextColor.SECONDARY} />}
      {option.color && <BadgeDot color={option.color} />}
    </>
  );

  return (
    <div key={option.value} className="flex flex-col gap-1">
      {showDivider && <Divider />}
      <DropdownItem
        label={option.label}
        active={!multiSelect && !isCustomAction && option.active}
        startElement={renderStartElement()}
        endElement={option.endElement}
        onClick={() => {
          if (isCustomAction && option.onClick) {
            option.onClick();
          } else {
            runOptionClick(option.value);
          }
        }}
        destructive={option.destructive}
        hovered={hovered}
        ref={assignRef(index)}
      />
    </div>
  );
};

export default BaseSelectOption;
