import { REACT_APP_ENV } from '../app.config';
import { SelectOption } from '../components';
import { LLMProviderID, Pronouns, ProspectPageTabs, ProspectType } from '../types';

export const HOTLINE_DEFAULT_PROSPECT_NAME = 'Amelia';
export const HOTLINE_DEFAULT_PHONE_NUMBER = REACT_APP_ENV === 'production' ? '+14159171029' : '+18086462818';

export const PROSPECT_TYPE_TO_LABEL: Record<ProspectType, string> = {
  [ProspectType.CANDIDATES]: 'Hiring screens',
  [ProspectType.FULLYRAMPED]: 'FullyRamped [Internal Only]',
  [ProspectType.GTM]: 'GTM [Internal Only]',
  [ProspectType.REPS]: 'Practice entire calls',
};

// -- PROSPECT PAGE -- //

/** The list of required fields for each tab. */
export const PROSPECT_REQUIRED_FIELDS: Record<ProspectPageTabs, string[]> = {
  [ProspectPageTabs.PERSONAL]: ['firstName', 'lastName', 'ttsModelId', 'personaVoiceId'],
  [ProspectPageTabs.LEAD]: ['jobTitle', 'roleType'],
  [ProspectPageTabs.ACCOUNT]: ['accountName', 'industry'],
  [ProspectPageTabs.SCENARIO]: ['successResult'],
  [ProspectPageTabs.CONFIGURATION]: [
    'firstMessage',
    'hangupPrompt',
    'sttModelId',
    'llmModel',
    'llmModelId',
    'llmTemperature',
    'llmMaxTokens',
    // 'promptTemplateId',
  ],
  [ProspectPageTabs.PROMPT]: [], // TODO: Add required fields for PROMPT tab
};

/** The limits for a prospect slider. */
export const PROSPECT_SLIDER_LIMITS = { default: 0.5, max: 1, min: 0, step: 0.1 };

// -- DEMOGRAPHICS -- //

/** The minimum age for a prospect. */
export const PROSPECT_MIN_AGE = 22;

/** The maximum age for a prospect. */
export const PROSPECT_MAX_AGE = 70;

/** The average age for a prospect. */
export const PROSPECT_AVERAGE_AGE = 38;

/** The variance for a prospect's age. */
export const PROSPECT_AGE_VARIANCE = 10;

/** The list of female first names used for random generation. */
export const FEMALE_FIRST_NAMES = [
  'Mary',
  'Patricia',
  'Jennifer',
  'Linda',
  'Elizabeth',
  'Barbara',
  'Susan',
  'Jessica',
  'Sarah',
  'Karen',
  'Lisa',
  'Nancy',
  'Betty',
  'Margaret',
  'Sandra',
  'Ashley',
  'Kimberly',
  'Emily',
  'Donna',
  'Michelle',
  'Carol',
  'Amanda',
  'Dorothy',
  'Melissa',
  'Deborah',
  'Stephanie',
  'Rebecca',
  'Sharon',
  'Laura',
  'Cynthia',
  'Kathleen',
  'Amy',
  'Angela',
  'Shirley',
  'Anna',
  'Ruth',
  'Brenda',
  'Pamela',
  'Nicole',
  'Katherine',
  'Samantha',
  'Christine',
  'Emma',
  'Catherine',
  'Debra',
  'Virginia',
  'Rachel',
  'Carolyn',
  'Janet',
  'Maria',
  'Heather',
  'Diane',
  'Julie',
  'Joyce',
  'Victoria',
  'Kelly',
  'Christina',
  'Lauren',
  'Joan',
  'Evelyn',
  'Olivia',
  'Judith',
  'Megan',
  'Cheryl',
  'Martha',
  'Andrea',
  'Frances',
  'Hannah',
  'Jacqueline',
  'Ann',
  'Gloria',
  'Jean',
  'Kathryn',
  'Alice',
  'Teresa',
  'Sara',
  'Janice',
  'Doris',
  'Madison',
  'Julia',
  'Grace',
  'Judy',
  'Abigail',
  'Marie',
  'Denise',
  'Beverly',
  'Amber',
  'Theresa',
  'Marilyn',
  'Danielle',
  'Diana',
  'Brittany',
  'Natalie',
  'Sophia',
  'Rose',
  'Isabella',
  'Alexis',
  'Kayla',
  'Charlotte',
  'Emma',
  'Alyssa',
  'Jane',
  'Lori',
  'Ava',
  'Clara',
  'Eleanor',
  'Lucy',
  'Audrey',
  'Anna',
  'Ruby',
  'Maya',
  'Eva',
  'Sophie',
  'Alice',
  'Victoria',
  'Luna',
  'Faith',
  'Hazel',
  'Violet',
  'Stella',
  'Nora',
  'Zoe',
  'Lily',
  'Aurora',
  'Scarlett',
  'Caroline',
  'Madeline',
  'Penelope',
  'Autumn',
  'Josephine',
  'Genesis',
  'Vivian',
  'Claire',
  'Skylar',
  'Lucy',
  'Bella',
  'Paisley',
  'Ellie',
  'Elena',
  'Naomi',
  'Maya',
  'Natalia',
  'Quinn',
  'Alexandra',
  'Ruby',
  'Kennedy',
  'Ivy',
  'Ariana',
  'Aaliyah',
  'Madelyn',
  'Alice',
  'Cora',
  'Lydia',
  'Sarah',
  'Eden',
  'Arianna',
  'Allison',
  'Gabriella',
  'Alice',
  'Nova',
  'Emilia',
  'Leah',
  'Lyla',
  'Madeline',
  'Valentina',
  'Everly',
  'Delilah',
  'Isla',
  'Diana',
  'Mariah',
  'Nevaeh',
  'Phoenix',
  'Journey',
  'Eloise',
  'Ada',
  'Daisy',
  'Josie',
  'Juliette',
  'Liliana',
  'Vanessa',
  'Adriana',
  'Leilani',
  'Sara',
  'Everleigh',
  'Willow',
  'Harmony',
  'Iris',
  'Georgia',
  'Arya',
  'Juliana',
  'Millie',
  'Londyn',
  'Rosalie',
  'Molly',
  'Amara',
  'Remi',
  'Joy',
  'Frances',
  'Shelby',
  'Danna',
  'Elsie',
  'Fiona',
  'Presley',
  'Alaia',
  'Keira',
  'Tessa',
  'Maggie',
  'Marina',
  'Cecilia',
  'Blair',
  'Oakley',
  'Sloane',
  'Daleyza',
  'Melanie',
  'River',
  'Legacy',
  'Mabel',
  'Jolene',
  'Blakely',
  'Kaia',
  'Nia',
  'Winter',
  'Olive',
  'Kali',
  'Amira',
  'Crystal',
  'Ellen',
  'Holland',
  'Jimena',
  'Julie',
  'Julianna',
  'Maisie',
  'Oaklynn',
  'Palmer',
  'Reign',
  'Robin',
  'Rosemary',
  'Salem',
  'Sidney',
  'Uma',
  'Willa',
  'Wren',
  'Yasmin',
  'Zaniyah',
  'Adelaide',
  'Anne',
  'Bonnie',
  'Colette',
  'Dawn',
  'Eileen',
];

/** The list of male first names used for random generation. */
export const MALE_FIRST_NAMES = [
  'James',
  'Robert',
  'John',
  'Michael',
  'William',
  'David',
  'Joseph',
  'Richard',
  'Charles',
  'Thomas',
  'Christopher',
  'Daniel',
  'Matthew',
  'Anthony',
  'Donald',
  'Mark',
  'Paul',
  'Steven',
  'Andrew',
  'Kenneth',
  'George',
  'Joshua',
  'Kevin',
  'Brian',
  'Edward',
  'Ronald',
  'Timothy',
  'Jason',
  'Jeffrey',
  'Ryan',
  'Gary',
  'Jacob',
  'Nicholas',
  'Eric',
  'Stephen',
  'Jonathan',
  'Larry',
  'Justin',
  'Scott',
  'Brandon',
  'Frank',
  'Benjamin',
  'Gregory',
  'Samuel',
  'Raymond',
  'Patrick',
  'Alexander',
  'Jack',
  'Dennis',
  'Jerry',
  'Tyler',
  'Aaron',
  'Henry',
  'Jose',
  'Douglas',
  'Peter',
  'Adam',
  'Nathan',
  'Zachary',
  'Walter',
  'Kyle',
  'Harold',
  'Carl',
  'Jeremy',
  'Gerald',
  'Keith',
  'Roger',
  'Arthur',
  'Terry',
  'Lawrence',
  'Sean',
  'Christian',
  'Ethan',
  'Austin',
  'Joe',
  'Albert',
  'Jesse',
  'Willie',
  'Billy',
  'Bryan',
  'Bruce',
  'Jordan',
  'Ralph',
  'Roy',
  'Noah',
  'Dylan',
  'Eugene',
  'Wayne',
  'Alan',
  'Juan',
  'Louis',
  'Russell',
  'Gabriel',
  'Randy',
  'Philip',
  'Harry',
  'Vincent',
  'Bobby',
  'Johnny',
  'Logan',
  'Mason',
  'Bradley',
  'Lucas',
  'Elijah',
  'Caleb',
  'Evan',
  'Isaac',
  'Isaiah',
  'Hunter',
  'Owen',
  'Connor',
  'Adrian',
  'Cole',
  'Alex',
  'Ian',
  'Cameron',
  'Julian',
  'Aiden',
  'Blake',
  'Carson',
  'Cooper',
  'Dominic',
  'Gavin',
  'Hudson',
  'Jeremiah',
  'Levi',
  'Miles',
  'Nolan',
  'Oliver',
  'Parker',
  'Quinn',
  'Sebastian',
  'Theodore',
  'Tucker',
  'Victor',
  'Wesley',
  'Xavier',
  'Yves',
  'Zane',
  'Abraham',
  'Bentley',
  'Calvin',
  'Dean',
  'Elliott',
  'Felix',
  'Graham',
  'Harrison',
  'Ivan',
  'Jasper',
  'Knox',
  'Leo',
  'Marcus',
  'Nash',
  'Oscar',
  'Paxton',
  'Quentin',
  'Reid',
  'Simon',
  'Theo',
  'Urban',
  'Vincent',
  'Winston',
  'Xavier',
  'Yuri',
  'Zander',
  'Adrian',
  'Barrett',
  'Caden',
  'Dallas',
  'Easton',
  'Felix',
  'Grayson',
  'Hayes',
  'Ibrahim',
  'Jace',
  'Kai',
  'Leonardo',
  'Magnus',
  'Neo',
  'Omar',
  'Phoenix',
  'Quinton',
  'Roman',
  'Sterling',
  'Tate',
  'Ulysses',
  'Vaughn',
  'Wade',
  'Xander',
  'Yale',
  'Zain',
  'Apollo',
  'Brooks',
  'Cruz',
  'Dante',
  'Ellis',
  'Flynn',
  'Griffin',
  'Hugo',
  'Iker',
  'Jett',
  'Knox',
  'Leon',
  'Milo',
  'Nixon',
  'Orion',
  'Pierce',
  'Quincy',
  'Reece',
  'Silas',
  'Titus',
  'Uri',
  'Vance',
  'Wells',
  'Xiomar',
  'York',
  'Zeke',
  'Ace',
  'Beckett',
  'Crew',
  'Denver',
  'Edison',
  'Ford',
  'Grey',
  'Harvey',
  'Ira',
  'Jude',
  'Keaton',
  'Legend',
  'Mack',
  'Nash',
  'Oak',
  'Porter',
  'Quest',
  'Raiden',
  'Saint',
  'Thor',
  'Utah',
  'Valor',
  'Wilder',
  'Xerxes',
  'Yates',
  'Zeus',
  'Archer',
  'Bear',
  'Cyrus',
  'Duke',
  'Echo',
  'Fox',
  'Gates',
];

/** The list of neutral first names used for random generation. */
export const NEUTRAL_FIRST_NAMES = [
  'Alex',
  'Avery',
  'Bailey',
  'Blair',
  'Casey',
  'Charlie',
  'Drew',
  'Eden',
  'Emerson',
  'Finley',
  'Frankie',
  'Harper',
  'Hayden',
  'Jamie',
  'Jordan',
  'Kennedy',
  'Lane',
  'Logan',
  'Madison',
  'Morgan',
  'Parker',
  'Peyton',
  'Quinn',
  'Reagan',
  'Reese',
  'Riley',
  'River',
  'Robin',
  'Rowan',
  'Ryan',
  'Sage',
  'Sam',
  'Sawyer',
  'Scout',
  'Shawn',
  'Sidney',
  'Skylar',
  'Spencer',
  'Storm',
  'Sydney',
  'Taylor',
  'Teagan',
  'Terry',
  'Tracy',
  'Val',
  'Winter',
  'Wren',
  'Cameron',
  'Angel',
  'Remy',
];

/** The list of last names used for random generation. */
export const LAST_NAMES = [
  'Smith',
  'Johnson',
  'Williams',
  'Brown',
  'Jones',
  'Garcia',
  'Miller',
  'Davis',
  'Rodriguez',
  'Martinez',
  'Hernandez',
  'Lopez',
  'Gonzalez',
  'Wilson',
  'Anderson',
  'Thomas',
  'Taylor',
  'Moore',
  'Jackson',
  'Martin',
  'Lee',
  'Perez',
  'Thompson',
  'White',
  'Harris',
  'Sanchez',
  'Clark',
  'Ramirez',
  'Lewis',
  'Robinson',
  'Walker',
  'Young',
  'Allen',
  'King',
  'Wright',
  'Scott',
  'Torres',
  'Nguyen',
  'Hill',
  'Flores',
  'Green',
  'Adams',
  'Nelson',
  'Baker',
  'Hall',
  'Rivera',
  'Campbell',
  'Mitchell',
  'Carter',
  'Roberts',
  'Gomez',
  'Phillips',
  'Evans',
  'Turner',
  'Diaz',
  'Parker',
  'Cruz',
  'Edwards',
  'Collins',
  'Reyes',
  'Stewart',
  'Morris',
  'Morales',
  'Murphy',
  'Cook',
  'Rogers',
  'Gutierrez',
  'Ortiz',
  'Morgan',
  'Cooper',
  'Peterson',
  'Bailey',
  'Reed',
  'Kelly',
  'Howard',
  'Ramos',
  'Kim',
  'Cox',
  'Ward',
  'Richardson',
  'Watson',
  'Brooks',
  'Chavez',
  'Wood',
  'James',
  'Bennett',
  'Gray',
  'Mendoza',
  'Ruiz',
  'Hughes',
  'Price',
  'Alvarez',
  'Castillo',
  'Sanders',
  'Patel',
  'Myers',
  'Long',
  'Ross',
  'Foster',
  'Jimenez',
];

/** Maps pronouns to first names. */
export const PRONOUNS_TO_FIRST_NAMES: Record<Pronouns, string[]> = {
  [Pronouns.HE_HIM_HIS]: MALE_FIRST_NAMES,
  [Pronouns.SHE_HER_HERS]: FEMALE_FIRST_NAMES,
  [Pronouns.THEY_THEM_THEIRS]: NEUTRAL_FIRST_NAMES,
};

/** List of TTS model options. */
export const TTS_MODEL_OPTIONS = [
  { value: 'eleven_turbo_v2', label: 'ElevenLabs / Turbo v2' },
  { value: 'eleven_turbo_v2_5', label: 'ElevenLabs / Turbo v2.5' },
  { value: 'eleven_multilingual_v2', label: 'ElevenLabs / Multilingual v2' },
];

/** List of voice options. */
export const VOICE_OPTIONS = [
  { value: 'JVmMgKJbp4ER2bqrITpV', label: 'Tersh' },
  { value: 'C3x1TEM7scV4p2AXJyrp', label: 'Zuri' },
  { value: 'F59Bv0jP18ftxRfYvYo5', label: 'Tatiana' },
  { value: 'cjVigY5qzO86Huf0OWal', label: 'Eric' },
  { value: 'jqVMajy0TkayOvIB8eCz', label: 'Karen' },
  { value: 'BfDbhCUVGzNgO4WXDRdy', label: 'Donny' },
  { value: 'zfpxqh60b0TrMkJHDLsR', label: 'Peter' },
  { value: 'wVZ5qbJFYF3snuC65nb4', label: 'Denise Richards' },
  { value: 'ZRwrL4id6j1HPGFkeCzO', label: 'Sam - Chill Southern California Male' },
  { value: 'J5iaaqzR5zn6HFG4jV3b', label: 'Joseph' },
  { value: 'mrh6BGtvw1pAXXEjIsOg', label: 'Aiden' },
  { value: 'aqXKinCxkMOvW6f3qU8l', label: 'Casual Cal' },
  { value: 'b5RPB35vTODb3BEmR3Fc', label: 'Serein - Casual American Friend' },
  { value: 'NHRgOEwqx5WZNClv5sat', label: 'Chelsea' },
  { value: 'IYu6nLyBOYJxKk7W3XX8', label: 'Richard-2' },
  { value: 'ySaYS84ykPC7FKlpD4ag', label: 'BlouB' },
  { value: 'CFv3umSzRiJJV5PBmDCF', label: 'Michael David Bennett' },
  { value: 't9IV45xnQb79w1JXFAIQ', label: 'Cecily - Professional' },
  { value: 'r27TA7xKV7nfUjudCBpS', label: 'Light Buzz Lenny - Natural' },
  { value: 'MnUw1cSnpiLoLhpd3Hqp', label: 'Heather Ray' },
  { value: 'RNnkVeW25AwKYxZgnHBH', label: 'The Great Conversationalist' },
  { value: 'QH0iMHD2kQD6054yg01N', label: 'New York Nick - Modern NYC Wiseguy' },
  { value: 'V5RKORVpYueDvTQHfALW', label: 'Beyond Average Joe' },
  { value: 'ZTho75k1M56OV0k9XtSC', label: 'Spence - Clear, Soft-Spoken American' },
  { value: 'yrT1876dlfqwBq29bT4p', label: 'Thomas G.' },
];

// -- LEAD -- //

/** The minimum tenure for a prospect's lead. */
export const PROSPECT_MIN_TENURE = 0;

/** The maximum tenure for a prospect's lead. */
export const PROSPECT_MAX_TENURE = 25;

/** The variance of the tenure for a prospect's lead. */
export const PROSPECT_TENURE_VARIANCE = 3;

/** The average tenure for a prospect's lead. */
export const PROSPECT_TENURE_AVERAGE = 3;

/** The list of job titles used for random generation. */
export const JOB_TITLES = [
  'Chief Executive Officer (CEO)',
  'Chief Operating Officer (COO)',
  'Chief Financial Officer (CFO)',
  'Chief Revenue Officer (CRO)',
  'Chief Marketing Officer (CMO)',
  'Chief Information Officer (CIO)',
  'Chief Technology Officer (CTO)',
  'Chief Product Officer (CPO)',
  'Vice President of Sales',
  'Vice President of Marketing',
  'Vice President of Operations',
  'Vice President of Business Development',
  'Vice President of Product',
  'Vice President of Finance',
  'Vice President of Customer Success',
  'Director of Sales',
  'Director of Marketing',
  'Director of Operations',
  'Director of Business Development',
  'Director of IT',
  'Director of Customer Success',
  'Director of Product Management',
  'Director of Finance',
  'Sales Manager',
  'Marketing Manager',
  'Operations Manager',
  'Business Development Manager',
  'Product Manager',
  'Customer Success Manager',
  'Finance Manager',
  'Account Executive',
  'Enterprise Account Executive',
  'Account Manager',
  'Territory Sales Manager',
  'Regional Sales Manager',
  'Inside Sales Representative',
  'Outside Sales Representative',
  'Sales Development Representative (SDR)',
  'Business Development Representative (BDR)',
  'Channel Sales Manager',
  'Partner Account Manager',
  'Customer Success Specialist',
  'Sales Engineer',
  'Solutions Architect',
  'IT Manager',
  'Procurement Manager',
  'Purchasing Manager',
  'Operations Coordinator',
  'Project Manager',
  'Channel Account Executive',
  'Senior Account Executive',
  'Client Relationship Manager',
  'Customer Success Director',
  'Solutions Engineer',
  'Sales Operations Manager',
  'Field Sales Representative',
  'Renewal Manager',
  'Revenue Operations Manager',
  'Partner Manager',
  'Growth Marketing Manager',
  'Demand Generation Manager',
  'Digital Marketing Manager',
  'Social Media Manager',
  'Content Marketing Manager',
  'Account Director',
  'Strategic Account Manager',
  'Implementation Specialist',
  'Product Marketing Manager',
  'Enterprise Client Partner',
  'Regional Account Manager',
  'Client Success Manager',
  'Sales Enablement Manager',
  'Partner Success Manager',
  'Corporate Account Manager',
  'Territory Account Executive',
  'Client Services Manager',
  'Business Analyst',
  'Data Analyst',
  'Financial Analyst',
  'IT Systems Administrator',
  'Solutions Consultant',
  'Enterprise Sales Manager',
  'Technical Account Manager',
  'Channel Sales Director',
  'Technical Sales Representative',
  'Lead Development Representative (LDR)',
  'Key Account Manager',
  'Client Partner',
  'Customer Support Manager',
  'Client Engagement Specialist',
  'Implementation Manager',
  'Client Experience Manager',
  'Community Manager',
  'Head of Sales',
  'Head of Marketing',
  'Director of Client Services',
  'Customer Support Representative',
  'Project Coordinator',
  'Partner Development Manager',
  'Regional Marketing Manager',
];

/** The list of departments used for random generation. */
export const DEPARTMENTS = [
  'Accounting',
  'Administration',
  'Advertising',
  'Analytics',
  'Asset Management',
  'Audit',
  'Benefits',
  'Brand Management',
  'Business Development',
  'Business Intelligence',
  'Communications',
  'Compliance',
  'Content',
  'Corporate Affairs',
  'Corporate Communications',
  'Corporate Development',
  'Corporate Strategy',
  'Customer Experience',
  'Customer Relations',
  'Customer Service',
  'Customer Success',
  'Data Analytics',
  'Data Science',
  'Design',
  'Digital Marketing',
  'E-commerce',
  'Engineering',
  'Enterprise Sales',
  'Environmental Health & Safety',
  'Ethics',
  'Event Management',
  'Facilities Management',
  'Finance',
  'Financial Planning & Analysis',
  'General Management',
  'Global Operations',
  'Government Relations',
  'Graphic Design',
  'Human Resources',
  'Information Security',
  'Information Technology',
  'Infrastructure',
  'Innovation',
  'Inside Sales',
  'Internal Communications',
  'International Business',
  'Inventory Management',
  'Investment Banking',
  'Investor Relations',
  'Legal',
  'Learning & Development',
  'Logistics',
  'Loss Prevention',
  'Manufacturing',
  'Market Research',
  'Marketing',
  'Media Relations',
  'Merchandising',
  'Mobile Development',
  'Network Operations',
  'Operations',
  'Organizational Development',
  'Payroll',
  'Performance Management',
  'Product Design',
  'Product Development',
  'Product Management',
  'Production',
  'Project Management',
  'Public Relations',
  'Purchasing',
  'Quality Assurance',
  'Quality Control',
  'Real Estate',
  'Recruitment',
  'Research & Development',
  'Risk Management',
  'Sales',
  'Sales Operations',
  'Security',
  'Social Media',
  'Software Development',
  'Strategic Planning',
  'Supply Chain',
  'Support',
  'Systems Administration',
  'Talent Acquisition',
  'Tax',
  'Technical Support',
  'Technology',
  'Training',
  'Treasury',
  'UX/UI Design',
  'Visual Design',
  'Web Development',
  'Warehouse',
  'Content Marketing',
  'Business Operations',
  'Creative Services',
  'Employee Relations',
];

/** The minimum number of direct reports for a prospect's lead. */
export const PROSPECT_MIN_NUM_OF_DIRECT_REPORTS = 0;

/** The maximum number of direct reports for a prospect's lead. */
export const PROSPECT_MAX_NUM_OF_DIRECT_REPORTS = 16;

/** The average number of direct reports for a prospect's lead. */
export const PROSPECT_NUM_OF_DIRECT_REPORTS_AVERAGE = 4;

/** The variance of the number of direct reports for a prospect's lead. */
export const PROSPECT_NUM_OF_DIRECT_REPORTS_VARIANCE = 2;

// -- ACCOUNT -- //

/** The list of company names used for random generation. */
export const COMPANY_NAMES = [
  'Apex Solutions',
  'Quantum Dynamics',
  'Blue Harbor Industries',
  'Nova Technologies',
  'Summit Enterprises',
  'Stellar Systems',
  'Pinnacle Corporation',
  'Frontier Innovations',
  'Atlas Global',
  'Vector Industries',
  'Meridian Solutions',
  'Phoenix Dynamics',
  'Titan Technologies',
  'Evergreen Enterprises',
  'Omega Systems',
  'Eclipse Corporation',
  'Horizon Technologies',
  'Zenith Industries',
  'Catalyst Innovations',
  'Prime Ventures',
  'Infinity Solutions',
  'Nexus Corporation',
  'Vertex Technologies',
  'Aurora Enterprises',
  'Delta Dynamics',
  'Spectrum Solutions',
  'Legacy Systems',
  'Matrix Corporation',
  'Pioneer Technologies',
  'Guardian Industries',
  'Helios Solutions',
  'Magna Systems',
  'Orion Technologies',
  'Paramount Enterprises',
  'Quantum Leap Solutions',
  'Renaissance Corporation',
  'Sage Technologies',
  'Trident Industries',
  'Unity Solutions',
  'Vanguard Systems',
  'Wavelength Technologies',
  'Xcel Corporation',
  'Yellowstone Enterprises',
  'Zephyr Solutions',
  'Alpine Technologies',
  'Beacon Industries',
  'Cascade Solutions',
  'Diamond Systems',
  'Eagle Corporation',
  'Falcon Technologies',
  'Genesis Enterprises',
  'Highland Solutions',
  'Impact Systems',
  'Jupiter Technologies',
  'Kinetic Industries',
  'Lighthouse Solutions',
  'Momentum Corporation',
  'Navigator Systems',
  'Oasis Technologies',
  'Pulse Enterprises',
  'Quest Solutions',
  'Radius Corporation',
  'Sapphire Technologies',
  'Thunder Industries',
  'Urban Solutions',
  'Vista Systems',
  'Weston Technologies',
  'Apex Global',
  'Yield Solutions',
  'Zodiac Corporation',
  'Avalon Technologies',
  'Bridge Industries',
  'Crown Solutions',
  'Doctrine Systems',
  'Echo Technologies',
  'Fusion Corporation',
  'Globe Enterprises',
  'Harbor Solutions',
  'Icon Technologies',
  'Jade Industries',
  'Knight Solutions',
  'Liberty Systems',
  'Metro Technologies',
  'Nordic Corporation',
  'Oceanic Solutions',
  'Peak Industries',
  'Quorum Technologies',
  'Redwood Enterprises',
  'Silver Solutions',
  'Terra Systems',
  'Universal Technologies',
  'Venture Corporation',
  'Western Solutions',
  'Xenon Technologies',
  'Yield Industries',
  'Zenith Global',
  'Acme Solutions',
  'Brightstar Technologies',
  'Coastal Industries',
  'Digital Dynamics',
  'Elite Systems',
  'Firefly Technologies',
  'Grand Solutions',
  'Haven Industries',
  'Insight Corporation',
  'Journey Technologies',
  'Kingdom Solutions',
  'Landmark Systems',
  'Meteor Technologies',
  'Northern Lights',
  'Odyssey Solutions',
  'Pacific rim Industries',
  'Quantum Edge',
  'River Technologies',
  'Sunburst Solutions',
  'Tidal Industries',
  'Upward Technologies',
  'Victory Systems',
  'Wisdom Corporation',
  'Xenith Solutions',
  'Yonder Technologies',
  'Zen Industries',
  'Anchor Solutions',
  'Blueprint Systems',
  'Compass Technologies',
  'Dreamscape Solutions',
  'Empire Industries',
  'Fortress Technologies',
  'Gateway Solutions',
  'Harmony Systems',
  'Innovative Dynamics',
  'Jubilee Technologies',
  'Keystone Solutions',
  'Landmark Industries',
  'Millennium Systems',
  'Northstar Technologies',
  'Oracle Solutions',
  'Paragon Industries',
  'Quantum Flux',
  'Rainbow Technologies',
  'Serenity Solutions',
  'Triumph Industries',
  'United Systems',
  'Velocity Technologies',
  'Wisdom Tree Solutions',
  'Xerxes Industries',
  'Yellow Brick',
  'Zero Point Technologies',
  'Alpine Vista',
  'Beacon Light Solutions',
  'Crystal Technologies',
  'Diamond Edge Systems',
  'Emerald Solutions',
  'Frostfire Technologies',
  'Golden Gate Industries',
  'Harvest Moon Solutions',
  'Imperial Systems',
  'Jade Dragon Technologies',
  'Kingdom Edge Solutions',
  'Lunar Technologies',
  'Maple Leaf Industries',
  'Neptune Systems',
  'Onyx Solutions',
  'Pearl Technologies',
  'Quest Point Industries',
  'Red Rock Solutions',
  'Sapphire Sky Systems',
  'Thunder Cloud Technologies',
  'Unity Point Solutions',
  'Valley Forge Industries',
  'Waterfall Technologies',
  'Xenon Systems',
  'Yellow Stone Solutions',
  'Zinc Technologies',
  'Aegis Industries',
  'Blue Mountain Systems',
  'Copper Technologies',
  'Dawn Star Solutions',
  'Echo Valley Industries',
  'First Light Technologies',
  'Green Field Solutions',
  'High Point Systems',
  'Iron Gate Technologies',
  'Jasper Solutions',
  'Kingfisher Industries',
  'Lake View Technologies',
  'Morning Star Solutions',
  'New World Systems',
  'Ocean Blue Technologies',
  'Pine Tree Solutions',
  'Quantum Reach Industries',
  'Rising Sun Technologies',
  'Silver Lake Solutions',
  'Twin Peaks Systems',
  'Upper Edge Technologies',
  'Valley View Solutions',
  'White Cloud Industries',
  'Xanadu Technologies',
  'Yellow River Solutions',
  'Zenith Peak Industries',
  'Aurora Sky Technologies',
  'Black Stone Solutions',
  'Cedar Technologies',
  'Desert Rose Industries',
  'East Wind Solutions',
  'Forest Green Technologies',
  'Golden Sun Industries',
  'Highland Park Solutions',
  'Ivory Tower Technologies',
  'Jade Mountain Industries',
  'Kings Canyon Solutions',
  'Lion Gate Technologies',
  'Midnight Sun Industries',
  'North Star Solutions',
  'Ocean Wave Technologies',
  'Palm Tree Industries',
  'Quantum Star Solutions',
  'Red River Technologies',
  'Silver Springs Industries',
  'Thunder Bay Solutions',
  'Union Square Technologies',
  'Valley Stream Industries',
  'White Sand Solutions',
  'Xavier Technologies',
  'Yellow Sea Industries',
  'Zion Technologies',
  'Alpine Peak Solutions',
  'Blue Ridge Industries',
  'Coral Sea Technologies',
  'Diamond Valley Solutions',
  'Eagle Peak Industries',
  'Fair Weather Technologies',
  'Grand Canyon Solutions',
  'Harbor View Industries',
  'Iron Mountain Technologies',
  'Jade Valley Solutions',
  'Kings Point Industries',
  'Lake Shore Technologies',
  'Maple Grove Solutions',
  'New Harbor Industries',
  'Ocean View Technologies',
  'Pine Valley Solutions',
  'Quantum Valley Industries',
  'Red Mountain Technologies',
  'Silver Creek Solutions',
  'Thunder Rock Industries',
  'Unity Valley Technologies',
  'Vista Point Solutions',
  'White Mountain Industries',
  'Xenon Valley Technologies',
  'Yellow Valley Solutions',
  'Zinc Valley Industries',
  'Azure Technologies',
  'Bright Valley Solutions',
  'Crystal Mountain Industries',
  'Deep Blue Technologies',
  'Echo Ridge Solutions',
  'Fair Valley Industries',
  'Green Valley Technologies',
  'High Valley Solutions',
  'Iron Valley Industries',
  'Jade Ridge Technologies',
  'Kings Valley Solutions',
  'Lake Ridge Industries',
  'Mountain View Technologies',
  'New Valley Solutions',
  'Ocean Ridge Industries',
  'Pine Ridge Technologies',
  'Quantum Ridge Solutions',
  'Red Ridge Industries',
  'Silver Ridge Technologies',
  'Thunder Valley Solutions',
  'Unity Ridge Industries',
  'Vista Ridge Technologies',
  'White Ridge Solutions',
  'Xenon Ridge Industries',
  'Yellow Ridge Technologies',
  'Zinc Ridge Solutions',
  'Apex Ridge Industries',
  'Blue Ridge Technologies',
  'Crystal Ridge Solutions',
  'Diamond Ridge Industries',
  'Eagle Ridge Technologies',
  'Fair Ridge Solutions',
  'Green Ridge Industries',
  'High Ridge Technologies',
  'Iron Ridge Solutions',
  'Jade Ridge Industries',
  'Kings Ridge Technologies',
  'Lake Ridge Solutions',
  'Mountain Ridge Industries',
  'New Ridge Technologies',
  'Ocean Ridge Solutions',
  'Pine Ridge Industries',
  'Quantum Ridge Technologies',
  'Red Ridge Solutions',
  'Silver Ridge Industries',
  'Thunder Ridge Technologies',
  'Unity Ridge Solutions',
  'Vista Ridge Industries',
  'Copper Canyon Tech',
  'Desert Storm Solutions',
  'Echo Springs Systems',
  'Frontier Edge Corp',
  'Golden Valley Inc',
  'Highland Tech',
  'Ironclad Systems',
  'Jupiter Peak LLC',
  'Kinetic Edge Solutions',
  'Lightning Bolt Tech',
  'Metro Systems Inc',
  'Northern Star Corp',
  'Ocean Breeze Tech',
  'Pacific Edge Systems',
  'Quantum Leap Corp',
  'Rainbow Tech',
  'Solar Systems Inc',
  'Thunder Cloud Corp',
  'Urban Edge Solutions',
  'Valley Tech Systems',
  'Western Star Inc',
  'Xenith Solutions LLC',
  'Yellow Brick Tech',
  'Zen Valley Systems',
  'Arctic Edge Corp',
  'Binary Systems Inc',
  'Coastal Tech',
  'Digital Edge Solutions',
  'Echo Tech Systems',
  'Future Corp',
  'Global Systems Inc',
  'Horizon Edge Tech',
  'Impact Solutions LLC',
  'Jade Systems Corp',
  'Kinetic Tech',
  'Laser Edge Systems',
  'Mercury Solutions Inc',
  'Nova Tech Corp',
  'Omega Systems LLC',
  'Pulse Edge Solutions',
  'Quantum Tech',
  'Rapid Systems Inc',
  'Swift Edge Corp',
  'Tech Valley Solutions',
  'United Systems LLC',
  'Vertex Tech',
  'Wave Solutions Inc',
  'Xenon Edge Systems',
  'Yellow Tech Corp',
  'Zinc Solutions LLC',
  'Alpha Systems Inc',
  'Beta Tech Corp',
  'Cyber Edge Solutions',
  'Digital Dynamics LLC',
  'Echo Systems Inc',
  'Flex Tech Corp',
  'Global Edge Solutions',
  'Hyper Systems LLC',
  'Info Tech Corp',
  'Jump Solutions Inc',
  'Kilo Systems LLC',
  'Logic Tech Corp',
  'Mega Solutions Inc',
  'Net Systems LLC',
  'Orbit Tech Corp',
  'Pixel Solutions Inc',
  'Quick Systems LLC',
  'Rapid Tech Corp',
  'Smart Solutions Inc',
  'Tech Systems LLC',
  'Ultra Corp',
  'Volt Solutions Inc',
  'Web Systems LLC',
  'Xcel Tech Corp',
  'Yield Solutions Inc',
  'Zero Systems LLC',
  'Atom Tech Corp',
  'Byte Solutions Inc',
  'Core Systems LLC',
  'Data Tech Corp',
  'Edge Solutions Inc',
  'Flow Systems LLC',
  'Grid Tech Corp',
  'Hub Solutions Inc',
  'Ion Systems LLC',
  'Jet Tech Corp',
  'Key Solutions Inc',
  'Link Systems LLC',
  'Max Tech Corp',
  'Node Solutions Inc',
  'Opt Systems LLC',
  'Port Tech Corp',
  'Quad Solutions Inc',
  'Root Systems LLC',
  'Sync Tech Corp',
  'Task Solutions Inc',
  'Unit Systems LLC',
  'Vast Tech Corp',
  'Wire Solutions Inc',
  'Xray Systems LLC',
  'Year Tech Corp',
  'Zone Solutions Inc',
  'App Systems LLC',
  'Bit Tech Corp',
  'Cloud Solutions Inc',
  'Dock Systems LLC',
  'Eco Tech Corp',
  'File Solutions Inc',
  'Gate Systems LLC',
  'Host Tech Corp',
  'Inet Solutions Inc',
  'Join Systems LLC',
  'Kern Tech Corp',
  'Loop Solutions Inc',
  'Mind Systems LLC',
  'Next Tech Corp',
  'Optix Solutions Inc',
  'Path Systems LLC',
  'Query Tech Corp',
  'Race Solutions Inc',
  'Safe Systems LLC',
  'Time Tech Corp',
  'User Solutions Inc',
  'View Systems LLC',
  'Wave Tech Corp',
  'Xcel Solutions Inc',
  'Yoke Systems LLC',
  'Zoom Tech Corp',
  'Arc Solutions Inc',
  'Base Systems LLC',
  'Cast Tech Corp',
  'Disk Solutions Inc',
  'Ease Systems LLC',
  'Fast Tech Corp',
  'Game Solutions Inc',
  'Heat Systems LLC',
  'Idea Tech Corp',
  'Jump Solutions Inc',
  'Keep Systems LLC',
  'Line Tech Corp',
  'Mail Solutions Inc',
  'Need Systems LLC',
  'Open Tech Corp',
  'Pure Solutions Inc',
  'Quest Systems LLC',
  'Real Tech Corp',
  'Sign Solutions Inc',
  'Team Systems LLC',
  'Unit Tech Corp',
  'Vent Solutions Inc',
  'Work Systems LLC',
  'Xact Tech Corp',
  'Year Solutions Inc',
  'Zone Systems LLC',
  'Air Tech Corp',
  'Bold Solutions Inc',
  'Code Systems LLC',
  'Deep Tech Corp',
  'Ever Solutions Inc',
  'Find Systems LLC',
  'Give Tech Corp',
  'Help Solutions Inc',
  'Iron Systems LLC',
  'Just Tech Corp',
  'Kind Solutions Inc',
  'Lift Systems LLC',
  'Move Tech Corp',
  'Note Solutions Inc',
  'Over Systems LLC',
  'Pack Tech Corp',
  'Quad Solutions Inc',
  'Ride Systems LLC',
  'Save Tech Corp',
  'Talk Solutions Inc',
  'Use Systems LLC',
  'Vast Tech Corp',
  'Walk Solutions Inc',
  'Xray Systems LLC',
  'Yard Tech Corp',
  'Zero Solutions Inc',
  'Aim Systems LLC',
  'Best Tech Corp',
  'Care Solutions Inc',
  'Data Systems LLC',
  'Echo Tech Corp',
  'Fill Solutions Inc',
  'Good Systems LLC',
  'Hold Tech Corp',
  'Info Solutions Inc',
  'Jump Systems LLC',
  'Keep Tech Corp',
  'Lead Solutions Inc',
  'Make Systems LLC',
  'Next Tech Corp',
  'Open Solutions Inc',
  'Post Systems LLC',
  'Quick Tech Corp',
  'Read Solutions Inc',
  'Send Systems LLC',
  'Tech Solutions Corp',
];

/** The list of industries used for random generation. */
export const INDUSTRIES = [
  'Healthcare Services',
  'Pharmaceuticals',
  'Biotechnology',
  'Information Technology',
  'Software Development',
  'Financial Services',
  'Real Estate',
  'Construction',
  'Manufacturing',
  'Automotive',
  'Retail Trade',
  'E-commerce',
  'Telecommunications',
  'Aerospace & Defense',
  'Insurance',
  'Energy',
  'Renewable Energy',
  'Oil and Gas',
  'Utilities',
  'Transportation and Logistics',
  'Hospitality and Leisure',
  'Food & Beverage',
  'Agribusiness',
  'Media & Entertainment',
  'Publishing',
  'Broadcasting',
  'Film Production',
  'Music and Performing Arts',
  'Advertising and Marketing',
  'Public Relations',
  'Education',
  'Higher Education',
  'Online Education',
  'Legal Services',
  'Accounting and Auditing',
  'Professional Consulting',
  'Management Consulting',
  'Environmental Services',
  'Waste Management',
  'Recycling',
  'Water Utilities',
  'Mining and Quarrying',
  'Chemical Manufacturing',
  'Electronics Manufacturing',
  'Semiconductor Manufacturing',
  'Apparel and Textiles',
  'Footwear',
  'Beauty and Personal Care',
  'Home Improvement',
  'Furniture Manufacturing',
  'Healthcare Equipment & Supplies',
  'Medical Devices',
  'Health Insurance',
  'Childcare Services',
  'Elder Care',
  'Fitness and Wellness',
  'Sports & Recreation',
  'Cybersecurity',
  'Cloud Computing',
  'Data Analytics',
  'Artificial Intelligence',
  'Blockchain Technology',
  'Virtual Reality/Augmented Reality',
  'Video Game Development',
  'Tourism and Travel',
  'Airlines',
  'Freight and Trucking',
  'Marine Transportation',
  'Railroads',
  'Financial Technology (Fintech)',
  'Investment Management',
  'Private Equity',
  'Venture Capital',
  'Human Resources',
  'Staffing and Employment Services',
  'Facilities Management',
  'Event Planning and Management',
  'Printing and Publishing',
  'Newspaper Publishing',
  'Radio Broadcasting',
  'Television Broadcasting',
  'Government',
  'Defense Contracting',
  'Social Services',
  'Nonprofit Organizations',
  'Religious Organizations',
  'Charitable Foundations',
  'International Trade',
  'Wholesale Distribution',
  'Plastics Manufacturing',
  'Paper and Pulp Manufacturing',
  'Agricultural Equipment',
  'Machinery Manufacturing',
  'Pharmaceutical Wholesale',
  'Auto Parts Manufacturing',
  'Battery Manufacturing',
  'Solar Technology',
  'Wind Energy',
  'Electric Vehicle Manufacturing',
  'Biotech Research and Development',
];

/** The list of cities used for random generation. */
export const CITIES = [
  'New York City, New York',
  'Los Angeles, California',
  'Chicago, Illinois',
  'Houston, Texas',
  'Phoenix, Arizona',
  'Philadelphia, Pennsylvania',
  'San Antonio, Texas',
  'San Diego, California',
  'Dallas, Texas',
  'San Jose, California',
  'Austin, Texas',
  'Jacksonville, Florida',
  'Fort Worth, Texas',
  'Columbus, Ohio',
  'San Francisco, California',
  'Charlotte, North Carolina',
  'Indianapolis, Indiana',
  'Seattle, Washington',
  'Denver, Colorado',
  'Washington, District of Columbia',
  'Boston, Massachusetts',
  'El Paso, Texas',
  'Nashville, Tennessee',
  'Detroit, Michigan',
  'Oklahoma City, Oklahoma',
  'Portland, Oregon',
  'Las Vegas, Nevada',
  'Memphis, Tennessee',
  'Louisville, Kentucky',
  'Baltimore, Maryland',
  'Milwaukee, Wisconsin',
  'Albuquerque, New Mexico',
  'Tucson, Arizona',
  'Fresno, California',
  'Sacramento, California',
  'Mesa, Arizona',
  'Kansas City, Missouri',
  'Atlanta, Georgia',
  'Omaha, Nebraska',
  'Colorado Springs, Colorado',
  'Raleigh, North Carolina',
  'Virginia Beach, Virginia',
  'Long Beach, California',
  'Miami, Florida',
  'Oakland, California',
  'Minneapolis, Minnesota',
  'Tulsa, Oklahoma',
  'Arlington, Texas',
  'Tampa, Florida',
  'New Orleans, Louisiana',
  'Wichita, Kansas',
  'Cleveland, Ohio',
  'Bakersfield, California',
  'Aurora, Colorado',
  'Anaheim, California',
  'Honolulu, Hawaii',
  'Santa Ana, California',
  'Riverside, California',
  'Corpus Christi, Texas',
  'Lexington, Kentucky',
  'Henderson, Nevada',
  'Stockton, California',
  'Saint Paul, Minnesota',
  'Cincinnati, Ohio',
  'St. Louis, Missouri',
  'Pittsburgh, Pennsylvania',
  'Greensboro, North Carolina',
  'Lincoln, Nebraska',
  'Anchorage, Alaska',
  'Plano, Texas',
  'Orlando, Florida',
  'Irvine, California',
  'Newark, New Jersey',
  'Durham, North Carolina',
  'Chula Vista, California',
  'Toledo, Ohio',
  'Fort Wayne, Indiana',
  'St. Petersburg, Florida',
  'Laredo, Texas',
  'Jersey City, New Jersey',
  'Chandler, Arizona',
  'Madison, Wisconsin',
  'Lubbock, Texas',
  'Scottsdale, Arizona',
  'Reno, Nevada',
  'Buffalo, New York',
  'Gilbert, Arizona',
  'Glendale, Arizona',
  'North Las Vegas, Nevada',
  'Winston-Salem, North Carolina',
  'Chesapeake, Virginia',
  'Norfolk, Virginia',
  'Fremont, California',
  'Garland, Texas',
  'Irving, Texas',
  'Hialeah, Florida',
  'Richmond, Virginia',
  'Boise, Idaho',
  'Spokane, Washington',
  'Baton Rouge, Louisiana',
  'Tacoma, Washington',
  'San Bernardino, California',
  'Modesto, California',
  'Fontana, California',
  'Des Moines, Iowa',
  'Moreno Valley, California',
  'Santa Clarita, California',
  'Fayetteville, North Carolina',
  'Birmingham, Alabama',
  'Oxnard, California',
  'Rochester, New York',
  'Port St. Lucie, Florida',
  'Grand Rapids, Michigan',
  'Huntsville, Alabama',
  'Salt Lake City, Utah',
  'Frisco, Texas',
  'Yonkers, New York',
  'Amarillo, Texas',
  'Glendale, California',
  'Huntington Beach, California',
  'McKinney, Texas',
  'Montgomery, Alabama',
  'Augusta, Georgia',
  'Aurora, Illinois',
  'Akron, Ohio',
  'Little Rock, Arkansas',
  'Tempe, Arizona',
  'Columbus, Georgia',
  'Overland Park, Kansas',
  'Grand Prairie, Texas',
  'Tallahassee, Florida',
  'Cape Coral, Florida',
  'Mobile, Alabama',
  'Knoxville, Tennessee',
  'Shreveport, Louisiana',
  'Worcester, Massachusetts',
  'Ontario, California',
  'Vancouver, Washington',
  'Sioux Falls, South Dakota',
  'Chattanooga, Tennessee',
  'Brownsville, Texas',
  'Fort Lauderdale, Florida',
  'Providence, Rhode Island',
  'Newport News, Virginia',
  'Rancho Cucamonga, California',
  'Santa Rosa, California',
  'Peoria, Arizona',
  'Oceanside, California',
  'Elk Grove, California',
  'Salem, Oregon',
  'Pembroke Pines, Florida',
  'Eugene, Oregon',
  'Garden Grove, California',
  'Cary, North Carolina',
  'Fort Collins, Colorado',
  'Corona, California',
  'Springfield, Missouri',
  'Jackson, Mississippi',
  'Alexandria, Virginia',
  'Hayward, California',
  'Lakewood, Colorado',
  'Clarksville, Tennessee',
  'Lancaster, California',
  'Salinas, California',
  'Palmdale, California',
  'Hollywood, Florida',
  'Springfield, Massachusetts',
  'Macon, Georgia',
  'Sunnyvale, California',
  'Pomona, California',
  'Kansas City, Kansas',
  'Killeen, Texas',
  'Escondido, California',
  'Pasadena, Texas',
  'Naperville, Illinois',
  'Bellevue, Washington',
  'Joliet, Illinois',
  'Murfreesboro, Tennessee',
  'Midland, Texas',
  'Rockford, Illinois',
  'Paterson, New Jersey',
  'Savannah, Georgia',
  'Bridgeport, Connecticut',
  'Torrance, California',
  'McAllen, Texas',
  'Syracuse, New York',
  'Surprise, Arizona',
  'Denton, Texas',
  'Roseville, California',
  'Thornton, Colorado',
  'Olathe, Kansas',
  'Carrollton, Texas',
  'Waco, Texas',
  'West Valley City, Utah',
  'Charleston, South Carolina',
  'Visalia, California',
  'Hampton, Virginia',
  'Warren, Michigan',
  'Gainesville, Florida',
  'Coral Springs, Florida',
];

/** The range of employees for each company size. */
export const COMPANY_SIZE_RANGES: [number, number][] = [
  [1, 10],
  [11, 20],
  [21, 50],
  [51, 100],
  [101, 200],
  [201, 500],
  [501, 1000],
  [1001, 2000],
  [2001, 5000],
  [5001, 10000],
  [10001, Infinity],
];

/** The range of annual revenue for each company. */
export const ANNUAL_REVENUE_RANGES: [number, number][] = [
  [0, 1000000],
  [1000000, 5000000],
  [5000000, 100000000],
  [100000000, 500000000],
  [500000000, Infinity],
];

// -- SCENARIO --

/** The maximum number of scenario items that can be randomly generated. */
export const MAX_RANDOM_SCENARIO_ITEMS = 3;

/** The list of priorities used for random generation. */
export const PRIORITIES = [
  'Revenue Growth, Achieve 15% year-over-year revenue growth',
  'Profit Margin, Increase net profit margin by 3 percentage points',
  'Customer Satisfaction, Maintain a customer satisfaction score (CSAT) above 90%',
  'Market Share, Expand market share by 5% in core markets',
  'Employee Retention, Maintain employee retention rate above 90%',
  'Digital Transformation, Complete 80% of digital transformation initiatives on schedule',
  'Operational Efficiency, Reduce operational costs by 10% through process optimization',
  'Innovation Pipeline, Launch 3 new products or services per quarter',
  'Customer Acquisition, Increase new customer acquisition rate by 25%',
  'Risk Management, Maintain risk incident rate below 1% of operations',
  'Cash Flow Management, Maintain positive operating cash flow with 15% growth',
  'Brand Awareness, Increase brand awareness by 20% in target markets',
  'Talent Development, Ensure 90% of employees complete development plans',
  'Quality Standards, Maintain product defect rate below 0.1%',
  'Cost Reduction, Achieve $5M in cost savings through efficiency initiatives',
  'Customer Retention, Improve customer retention rate to 95%',
  'Sales Growth, Increase sales pipeline value by 30%',
  'Technology Infrastructure, Achieve 99.9% system uptime',
  'Data Analytics Adoption, Implement data-driven decision making in 90% of departments',
  'Sustainability Goals, Reduce carbon footprint by 25%',
  'Supply Chain Optimization, Reduce supply chain costs by 12%',
  'Marketing ROI, Achieve 300% return on marketing investment',
  'Employee Engagement, Maintain employee engagement score above 85%',
  'Product Development, Reduce time-to-market by 30%',
  'Regulatory Compliance, Maintain 100% compliance with regulatory requirements',
  'Stakeholder Relations, Achieve stakeholder satisfaction rating of 90%',
  'Crisis Preparedness, Complete quarterly crisis management drills with 95% readiness',
  'International Expansion, Enter 2 new international markets successfully',
  'Process Standardization, Implement standard operating procedures across 95% of processes',
  'Cybersecurity, Achieve zero critical security breaches',
  'Corporate Culture, Reach employee culture alignment score of 85%',
  'Business Model Innovation, Generate 20% of revenue from new business models',
  'Knowledge Management, Achieve 90% documentation of critical processes',
  'Project Success Rate, Maintain 85% project completion rate on time and budget',
  'Customer Service, Resolve 95% of customer issues within 24 hours',
  'Asset Utilization, Improve asset utilization rate to 85%',
  'Workplace Safety, Maintain zero workplace incidents',
  'Innovation Culture, Generate 100 employee innovation proposals per quarter',
  'Strategic Partnerships, Establish 5 new strategic partnerships annually',
  'Digital Presence, Increase digital engagement by 40%',
  'Workforce Development, Complete 40 hours of training per employee annually',
  'Quality Improvement, Reduce customer complaints by 50%',
  'Financial Planning, Maintain budget variance within 5%',
  'Customer Insights, Conduct monthly customer feedback analysis with 80% response rate',
  'Organizational Efficiency, Reduce decision-making time by 30%',
  'Business Continuity, Achieve 100% backup system functionality',
  'Market Intelligence, Provide weekly competitive analysis reports',
  'Performance Management, Complete 100% of quarterly performance reviews',
  'Product Quality, Achieve 98% first-pass yield rate',
  'Strategic Execution, Complete 90% of strategic initiatives on schedule',
];

/** The list of objections used for random generation. */
export const OBJECTIONS = [
  "I'm not interested.",
  "We're already working with someone.",
  "I'm too busy right now.",
  'Send me an email instead.',
  "It's too expensive.",
  "I'm not the right person to talk to.",
  "I don't have time to talk.",
  "We don't have the budget.",
  'Call me back later.',
  "We don't need this product/service.",
  "I don't make those decisions.",
  "We're under contract with another vendor.",
  "We're happy with our current provider.",
  "I'm not sure if this is worth it.",
  "I've heard bad things about your company.",
  "We don't work with new vendors.",
  "I've never heard of your company.",
  "This isn't a priority right now.",
  "I'm not authorized to make purchases.",
  "I'm just gathering information.",
  "We're already handling this in-house.",
  'Can you call back after the quarter?',
  'How did you get my number?',
  'This sounds too good to be true.',
  "It doesn't fit our needs.",
  'I need to run this by someone else.',
  "I don't think it will work for us.",
  "We don't have the infrastructure for this.",
  "We're not looking to change anything right now.",
  'What makes you different from others?',
  'Our budget has been cut.',
  "We're going through a hiring freeze.",
  "We're satisfied with our solution.",
  "I've seen this type of product before.",
  "There's no need for this right now.",
  "We're in a closed market.",
  "It's not a priority in our strategy.",
  'Your product/service is untested.',
  "We're focused on other areas.",
  'We prefer local vendors.',
  "I'm just not interested.",
  "We're not looking to buy right now.",
  "You're calling at a bad time.",
  'Can you prove ROI on this?',
  "We don't have the right resources.",
  "I'm on a deadline and can't talk.",
  "I don't know enough about this product.",
  "We've tried something similar before.",
  "There's no need for this in my role.",
  "I don't see the value.",
];

/** The list of questions used for random generation. */
export const QUESTIONS = [
  'Who is this?',
  'How did you get my contact information?',
  'What is this about?',
  'Why are you calling me?',
  'Are you a salesperson?',
  'How did you find out about us?',
  'What company are you with?',
  'Is this a cold call?',
  'What exactly do you offer?',
  'What problem does your product solve?',
  'Why should I care about this?',
  'What makes your product different from others?',
  'How can you help me with [specific problem]?',
  'What’s the cost?',
  'Can you send me more information?',
  'Do you have any customer testimonials?',
  'Do you have a website I can look at?',
  'Can I get a demo of your product?',
  'How long have you been in business?',
  'Who else is using your product?',
  'Is your product proven to work?',
  'How do you know your product will work for me?',
  'Do you offer a free trial?',
  'What’s the ROI on this?',
  'What are the benefits of your product?',
  'How soon can we see results?',
  'What happens after I sign up?',
  'Is there a contract involved?',
  'Are there any hidden fees?',
  'Do you have any discounts or promotions?',
  'What’s the catch?',
  'What’s your cancellation policy?',
  'Can I try it before committing?',
  'Are there any alternatives I should consider?',
  'What’s the next step?',
  'How much time will this take to implement?',
  'Can you guarantee results?',
  'Is your product easy to use?',
  'Can you help me integrate this into my system?',
  'What kind of support do you offer?',
  'Do you provide training?',
  'How do I get started?',
  'Is this solution scalable?',
  'What’s your customer support like?',
  'Who can I contact if I need help?',
  'Do you have references I can talk to?',
  'What happens if I’m not satisfied?',
  'What’s your refund policy?',
  'Do you offer any upgrades or additional features?',
  'Can I speak to someone who’s used the product?',
];

/** The list of background prompts used for random generation. */
export const BACKGROUND_PROMPTS = [
  'In the car with a child on the way to dinner.',
  'At home, working from the kitchen table.',
  'Walking through a crowded shopping mall.',
  'In a coffee shop, waiting for a friend.',
  'On the train during a morning commute.',
  'Walking the dog in the park.',
  'Sitting at a restaurant during lunch.',
  "In a doctor's office, waiting for an appointment.",
  'At a grocery store, shopping for dinner.',
  'At the gym, on the treadmill.',
  'Sitting in traffic during rush hour.',
  'On a plane, waiting for takeoff.',
  'At a conference, waiting for a session to start.',
  'In a quiet library, reading.',
  'At a crowded concert, waiting for the show to begin.',
  'In a hotel lobby, checking in.',
  'On a bus, heading home after work.',
  'At a noisy family gathering.',
  'Walking around a busy city street.',
  'In a hotel room, unpacking after checking in.',
  'In a car, picking up kids from school.',
  'On a beach, relaxing under an umbrella.',
  'On a boat, out on the water.',
  'At a sports game, sitting in the stands.',
  'In a warehouse, preparing for the next shipment.',
  'In an airport terminal, waiting for a flight.',
  'At a wedding reception, talking to guests.',
  'At a bar, enjoying happy hour.',
  'In the office, at a workstation.',
  'In a car, driving to a meeting.',
  'At a picnic, enjoying lunch with friends.',
  'On a hiking trail, taking a break.',
  'At a theme park, waiting in line for a ride.',
  'In a quiet home office, attending a virtual meeting.',
  'In a park, sitting on a bench.',
  'In a school parking lot, waiting for a child to finish practice.',
  'At a construction site, supervising the crew.',
  'At a family reunion, catching up with relatives.',
  'At a pet store, shopping for pet supplies.',
  'In a car, waiting in a long drive-thru line.',
  'In a fast food restaurant, ordering lunch.',
  'On a long road trip, stopping for gas.',
  'At a bookshop, browsing new releases.',
  'In a movie theater, waiting for the film to start.',
  'In a parking garage, walking to the car.',
  'At a home improvement store, shopping for tools.',
  'At a daycare center, picking up a child.',
  'At a park bench, enjoying a coffee.',
  'At a zoo, looking at the animals.',
  'In an elevator, on the way up to an office floor.',
];

// -- CONFIGURATION --

/** The list of first messages used for random generation. */
export const FIRST_MESSAGES = [
  'Hello?',
  "Who's this?",
  "Hello, may I ask who's calling?",
  "Hi, who's this?",
  "Yes, who's speaking?",
  'Hello, how can I help you?',
  "Hello, what's this about?",
  'What do you want?',
  "Hello, this is [Name], who's calling?",
  'Who is this, please?',
  'Can I help you with something?',
  'Hi, this is a bit of a surprise!',
  "Hello, what's going on?",
  "What's up?",
  "Yes, who's calling?",
  'Hello, is there something I can do for you?',
  "Who's on the other line?",
  'Hello, who am I speaking with?',
  'Yes, how can I assist you?',
  "Hello, I wasn't expecting a call.",
  'Who gave you my number?',
  "Is this about Charlotte's doctors appointment?",
  'What is this regarding?',
  "What's this call about?",
  "I wasn't expecting anyone.",
  'Hello, what can I do for you?',
  "What's going on?",
  "Who's calling from this number?",
  'Can I help you?',
  "I'm not sure I know you.",
  'Hello, do I know you?',
  'What is it you want?',
  "Hi, what's this about?",
  'Is this a telemarketer?',
  "Who's calling and why?",
  "I don't recognize this number, who's this?",
  'Can I call you back later?',
  'What can I do for you today?',
  "Hello, I'm not sure what this is about.",
  "Hi, who's this and how did you get my number?",
  "Hello, what's the reason for your call?",
  'Can you leave a message?',
  "I didn't expect anyone today.",
  "Hello, this is [Name], what's going on?",
  "I'm not expecting a call.",
  'Who is this and how did you get my information?',
  "Hello, I'm not available right now.",
  "Who is this? I wasn't expecting a call.",
  "I'm sorry, who's calling?",
  'Hello, do you have an appointment with me?',
  "I wasn't expecting any calls today.",
  "What's this call about?",
  "Yes, who's speaking?",
  'Who are you looking for?',
  "What's the reason for the call?",
  'Hello, is this important?',
  'Is this a wrong number?',
  "I didn't think anyone would be calling me right now.",
  "What's going on, who is this?",
  'Hello, how can I assist you today?',
  "I'm not sure who this is.",
  'Who are you trying to reach?',
  'Is this urgent?',
  'Hi, who is this and how can I help?',
  "I wasn't expecting this call.",
  'What do you need?',
  'Can you call back later?',
  "I'm not sure I know you.",
  'Hello, who am I speaking with?',
  'Hi, is this about my reservation?',
  'Why are you calling me?',
  "Hello, who's calling from this number?",
  'Hello, what can I do for you?',
  'Is there something I can help with?',
  "Who is calling and what's it about?",
  'Hello, what do you need from me?',
  "Hi, I didn't expect your call.",
  "Who's calling? This is a bit unexpected.",
  "Hello, this is [Name]. What's going on?",
  'Is this a sales call?',
  'Can you leave a voicemail?',
  "Who's calling me from this number?",
  "I'm not familiar with your number.",
  "Hello, I wasn't expecting a call right now.",
  "What is this about? Who's calling?",
  "What's going on, is everything alright?",
  "Who's speaking? I didn't catch that?",
  'Is this the right number for [person]?',
  'Can I take a message?',
  "What's up, who's this?",
  'Hello, do I know you?',
  "What's the reason for your call?",
  "I wasn't expecting a call from this number.",
  "Hello, who's calling and what is it about?",
  "Hello, you've got me at a busy time.",
  'Who is calling, please?',
  "Hi, what's this about?",
  'Hello, can I help you with something?',
  'What is this call regarding?',
  "I'm a bit busy, who is this?",
];

/** The LLM temperature slider limits. */
export const LLM_TEMPERATURE_SLIDER_LIMITS = { default: 0.8, min: 0, max: 2.0, step: 0.1 };

/** The LLM max tokens slider limits. */
export const LLM_MAX_TOKENS_SLIDER_LIMITS = { default: 250, min: 0, max: 8000, step: 50 };

/** Represents the STT model options. */
export const STT_MODEL_OPTIONS = [{ value: 'nova-2-phonecall', label: 'Deepgram / Nova 2 phonecall' }];

/** Represents the LLM provider options. */
export const LLM_PROVIDER_OPTIONS = [
  { value: LLMProviderID.GPT, label: 'OpenAI' },
  { value: LLMProviderID.ANTHROPIC, label: 'Anthropic' },
  { value: LLMProviderID.LLAMA, label: 'Llama' },
  { value: LLMProviderID.GEMINI, label: 'Gemini' },
  { value: LLMProviderID.OPENROUTER, label: 'OpenRouter' },
];

/** Maps the LLM provider ID to the select options of the LLM provider. */
export const LLM_PROVIDER_ID_MODEL_OPTIONS: Record<LLMProviderID, SelectOption[]> = {
  [LLMProviderID.GPT]: [
    { value: 'gpt-4', label: 'GPT 4' },
    { value: 'gpt-4o', label: 'GPT 4o' },
    { value: 'gpt-4o-mini', label: 'GPT 4o mini' },
    { value: 'gpt-4-turbo', label: 'GPT 4 turbo' },
    { value: 'o1-mini-2024-09-12', label: 'GPT o1 mini' },
  ],
  [LLMProviderID.ANTHROPIC]: [
    { value: 'claude-3-5-sonnet-20240620', label: 'Claude 3.5 Sonnet' },
    { value: 'claude-3-haiku-20240307', label: 'Claude 3 Haiku' },
  ],
  [LLMProviderID.LLAMA]: [{ value: 'llama-3.1-8b-instant', label: 'Llama 3.1.8b Instruct' }],
  [LLMProviderID.GEMINI]: [
    { value: 'gemini-1.5-flash', label: 'Gemini 1.5 Flash' },
    { value: 'gemini-1.5-pro', label: 'Gemini 1.5 Pro' },
  ],
  [LLMProviderID.OPENROUTER]: [{ value: 'microsoft/wizardlm-2-7b', label: 'Wizard 2 7b' }],
};
