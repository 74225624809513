import clsx from 'clsx';
import Radio from '../Radio/Radio';
import { RadioGroupProps } from './RadioGroup.types';

const RadioGroup = ({ options, value, onChange, className }: RadioGroupProps) => {
  return (
    <div className={clsx('flex items-center gap-4', className)}>
      {options.map((option) => (
        <Radio
          key={option.value}
          value={option.value}
          label={option.label}
          checked={value === option.value}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

export default RadioGroup;
