import clsx from 'clsx';
import * as React from 'react';

interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
  className?: string;
}

const Table = React.forwardRef<HTMLTableElement, TableProps>(({ className, ...props }, ref) => (
  <div className={clsx('rounded-md border', className)}>
    <table ref={ref} className="table w-full table-fixed" {...props} />
  </div>
));

Table.displayName = 'Table';

export default Table;
