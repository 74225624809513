import { useCallback, useMemo } from 'react';

import { useGetGongUsersQuery } from '../../../services';
import { GongUser } from '../../../types';
import {
  SelectOption,
  TextButton,
  ButtonColor,
  Typography,
  TypographySize,
  TypographyWeight,
  Select,
  SidebarSelectFilter,
} from '../../shared';
import { SIDEBAR_WIDTH, DATE_OPTIONS } from '../../../constants';

interface GongCallsFiltersProps {
  selectedParticipants: SelectOption[];
  setSelectedParticipants: (participants: SelectOption[]) => void;
  setSelectedDate: (date?: SelectOption) => void;
  selectedDate?: SelectOption;
}

const getDisplayName = (user: GongUser) => {
  // Check if user has a name (first or last)
  const hasName = user.firstName || user.lastName;

  if (hasName) {
    const firstName = user.firstName || '';
    const lastName = user.lastName || '';
    return `${firstName} ${lastName}`.trim();
  }

  // Fallback to email or phone
  return user.emailAddress || user.phoneNumber;
};

const GongCallsFilters = ({
  selectedParticipants,
  setSelectedParticipants,
  selectedDate,
  setSelectedDate,
}: GongCallsFiltersProps) => {
  const { data: gongUsers, isLoading: isLoadingGongUsers } = useGetGongUsersQuery();

  // Memoized options for the participants filter list
  const participantOptions = useMemo(() => {
    if (!gongUsers) return [];

    return gongUsers?.users?.map((user) => ({
      value: user.id,
      label: getDisplayName(user),
    }));
  }, [gongUsers]);

  // Handles clearing the filters
  const handleClearFilters = () => {
    setSelectedParticipants([]);
    setSelectedDate(undefined);
  };

  // Handles changing the date filter
  const handleDateChange = useCallback(
    (selected?: string) => {
      if (!selected) {
        // Clear the date filter if no value is selected
        setSelectedDate(undefined);
        return;
      }

      // Find the selected date option
      const selectedWithLabels = DATE_OPTIONS.find((opt) => opt.value === selected);
      setSelectedDate(selectedWithLabels);
    },
    [setSelectedDate]
  );

  // Handles changing the participants filter
  const handleParticipantChange = useCallback(
    (selected?: string[]) => {
      // Clear the participants filter if no value is selected
      if (!selected || selected.length === 0) {
        setSelectedParticipants([]);
        return;
      }

      // Find the selected participant options
      const selectedWithLabels = selected?.map((value) => {
        const option = participantOptions.find((opt) => opt.value === value);
        return { value, label: option?.label ?? value };
      });
      setSelectedParticipants(selectedWithLabels);
    },
    [participantOptions, setSelectedParticipants]
  );

  const hasFilters = selectedParticipants.length > 0 || selectedDate;

  return (
    <div
      className="flex h-full w-full flex-col gap-4 overflow-hidden rounded-md border p-6"
      style={{ width: SIDEBAR_WIDTH }}
    >
      <Typography size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD} className="sticky top-0">
        Filters
      </Typography>
      <div className="flex flex-col gap-4">
        {/* Call participants filter */}
        <SidebarSelectFilter
          placeholder="Type to filter"
          title="Call participants"
          options={participantOptions}
          onChange={(selected) => handleParticipantChange(selected)}
          loading={isLoadingGongUsers}
          selected={selectedParticipants}
          clearable
        />
        {/* Call date filter */}
        <div className="flex flex-col gap-2">
          <Typography>Call date</Typography>
          <Select
            selected={selectedDate}
            options={DATE_OPTIONS}
            onChange={(selected) => handleDateChange(selected)}
            placeholder="Select call date"
            clearable
          />
        </div>
        {/* Clear filters button */}
        {hasFilters && (
          <TextButton text="Clear filters" onClick={handleClearFilters} fullWidth color={ButtonColor.SECONDARY} />
        )}
      </div>
    </div>
  );
};

export default GongCallsFilters;
