import { useCallback, useEffect, useState } from 'react';
import { ButtonColor, Icon, IconButton, Tabs, TabsType, TagsRow } from '../../../components';
import { useAppDispatch } from '../../../hooks';
import { setCurrentCallDetails } from '../../../redux/reducers';
import { Call } from '../../../types';
import { capitalizeFirstLetter } from '../../../utils';
import { CallScorecard } from './CallScorecard';
import SummaryTab from './SummaryTab';
import TranscriptTab from './TranscriptTab';

// Enum for tab identifiers
enum CallDetailsTabs {
  SUMMARY = 'SUMMARY',
  TRANSCRIPT = 'TRANSCRIPT',
}

const CALL_DETAILS_TABS = Object.values(CallDetailsTabs).map((tab) => ({ id: tab, title: capitalizeFirstLetter(tab) }));
const DEFAULT_ACTIVE_TAB = CallDetailsTabs.SUMMARY;

interface CallDetailsDrawerContentProps {
  call: Call;
  isOpen: boolean;
  onClose: () => void;
}

const CallDetailsDrawerContent = ({ call, isOpen, onClose }: CallDetailsDrawerContentProps) => {
  const dispatch = useAppDispatch();

  // State to manage the active tab.
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);
  const [isScorecardOpen, setIsScorecardOpen] = useState(false);

  const {
    callSid,
    practiceProspect: { tags },
  } = call;

  // Handles seeking the current call to a specific time.
  const seekAudio = useCallback(
    (startTime: number) => {
      // Enable auto-playing the audio to provide a seamless experience for the user,
      // enabling them to immediately hear the context of the selected transcript time.
      dispatch(setCurrentCallDetails({ callSid, startTime, autoPlay: true }));
    },
    [dispatch, callSid]
  );

  // Reset the drawer state when the drawer is closed.
  useEffect(() => {
    if (!isOpen) {
      setActiveTab(DEFAULT_ACTIVE_TAB);
      setIsScorecardOpen(false);
    }
  }, [isOpen]);

  return (
    <div className="flex h-full flex-col">
      <div className="flex gap-4 border-b border-b-neutral-200 bg-white p-4 shadow-sm">
        <Tabs
          tabs={CALL_DETAILS_TABS}
          activeTab={activeTab}
          setActiveTab={(newActiveTab) => setActiveTab(newActiveTab as CallDetailsTabs)}
          className="!gap-4"
          type={TabsType.GHOST}
        />

        <div className="flex w-full items-center gap-4 overflow-auto">
          <TagsRow tags={tags} />
          <IconButton icon={Icon.MINIMIZE} onClick={onClose} tooltip="Close" color={ButtonColor.SECONDARY} />
        </div>
      </div>

      <div className="flex flex-grow overflow-hidden">
        <div className="display-scrollbar-lg flex-grow overflow-auto p-4">
          {activeTab === CallDetailsTabs.SUMMARY && <SummaryTab call={call} seekAudio={seekAudio} />}
          {activeTab === CallDetailsTabs.TRANSCRIPT && <TranscriptTab call={call} seekAudio={seekAudio} />}
        </div>

        <CallScorecard isScorecardOpen={isScorecardOpen} setIsScorecardOpen={setIsScorecardOpen} />
      </div>
    </div>
  );
};

export default CallDetailsDrawerContent;
