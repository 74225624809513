import { useState } from 'react';
import { PRIVACY_POLICY_URL } from '../../../constants';
import { TextColor } from '../../../types';
import { Dialog, DialogType, Tabs, TabsType, Typography, TypographySize } from '../../shared';
import { AITwinTab, CustomProspectTab, ScorecardTab } from './CreateTabs';

enum CreateTab {
  CUSTOM_PROSPECT = 'CUSTOM_PROSPECT',
  AI_TWIN = 'AI_TWIN',
  SCORECARD = 'SCORECARD',
}

interface CreateModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateModal = ({ isOpen, onClose }: CreateModalProps) => {
  const [activeTab, setActiveTab] = useState(CreateTab.CUSTOM_PROSPECT);

  return (
    <Dialog
      isOpen={isOpen}
      title="Create"
      onClose={onClose}
      type={DialogType.SETTINGS}
      footer={
        <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
          What you upload and share is private to your workspace. It is used to create realistic Practice Prospects, we
          do not train AI models using this information.&nbsp;
          <span className="cursor-pointer underline" onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}>
            Privacy policy
          </span>
        </Typography>
      }
    >
      <Tabs
        activeTab={activeTab}
        tabs={[
          {
            id: CreateTab.CUSTOM_PROSPECT,
            title: 'Custom prospect',
            content: <CustomProspectTab onClose={onClose} />,
          },
          {
            id: CreateTab.AI_TWIN,
            title: 'AI twin',
            content: <AITwinTab onClose={onClose} />,
          },
          {
            id: CreateTab.SCORECARD,
            title: 'Scorecard',
            content: <ScorecardTab onClose={onClose} />,
          },
        ]}
        setActiveTab={(newActiveTab) => setActiveTab(newActiveTab as CreateTab)}
        type={TabsType.LIFTED}
      />
    </Dialog>
  );
};

export default CreateModal;
