import { Typography } from '../Typography';
import { RadioProps } from './Radio.types';

const Radio = ({ value, label, checked, onChange }: RadioProps) => {
  return (
    <div className="flex items-center gap-2">
      <input type="radio" value={value} checked={checked} onChange={onChange} className="radio" />
      <Typography>{label}</Typography>
    </div>
  );
};

export default Radio;
