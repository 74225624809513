import { Icon, Icons, TypographySize, Typography, Dialog, ButtonColor, TextButton } from '../../shared';
import { TextColor } from '../../../types';

const ICON_SIZE = 48;

interface GongSuccessModalProps {
  isOpen: boolean;
  numOfProspects: number;
  onClose: () => void;
}

const GongSuccessModal = ({ isOpen, numOfProspects = 1, onClose }: GongSuccessModalProps) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={`Practice Prospect${numOfProspects > 1 ? 's are' : ' is'} processing`}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col items-center gap-2">
          <Icons icon={Icon.ORIGAMI} color={TextColor.LINK} size={ICON_SIZE} />
          <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
            {`We’re building your Practice Prospect${numOfProspects > 1 ? 's' : ''}! We’ll email you when your AI twin is complete, usually within 24
      hours.`}
          </Typography>
        </div>
        <div className="flex justify-end">
          <TextButton text="Close" onClick={onClose} color={ButtonColor.SECONDARY} />
        </div>
      </div>
    </Dialog>
  );
};

export default GongSuccessModal;
