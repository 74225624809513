import clsx from 'clsx';

import { Icon, Icons, Typography, TypographySize } from '../../../components';
import { TextColor } from '../../../types/color.types';

interface ProspectPageFieldProps {
  children: React.ReactNode;
  label: string;
  fitWidth?: boolean;
  fullWidth?: boolean;
  onRandomize?: () => void;
}

const ProspectPageField = ({ children, label, fitWidth, fullWidth, onRandomize }: ProspectPageFieldProps) => {
  return (
    <div className={clsx('flex flex-col gap-2', fullWidth ? 'flex-1' : 'flex-0', fitWidth && 'w-fit')} key={label}>
      <div className="flex items-center gap-1">
        <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY} className="select-none">
          {label}
        </Typography>
        {onRandomize && <Icons icon={Icon.WAND} color={TextColor.AI} onClick={onRandomize} tooltip="Fill for me" />}
      </div>
      {children}
    </div>
  );
};

export default ProspectPageField;
