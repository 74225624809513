import { useRef } from 'react';
import { Typography, TypographySize } from '../../shared';
import { TextColor, DropdownPlacement, GongCall } from '../../../types';
import { useDetectTextOverflow } from '../../../hooks';

const MAX_LINES = 1;

interface CallInfoCellProps {
  call: GongCall;
}

const CallInfoCell = ({ call }: CallInfoCellProps) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const subtitleRef = useRef<HTMLDivElement>(null);

  const participants = call.parties;
  const participantsText = participants?.map((party) => party.name).join(', ');

  const isTitleOverflowing = useDetectTextOverflow(titleRef, MAX_LINES);
  const isSubtitleOverflowing = useDetectTextOverflow(subtitleRef, MAX_LINES);

  const getTooltip = (text: string) => ({
    content: text,
    placement: DropdownPlacement.BOTTOM,
  });

  return (
    <div className="flex flex-col gap-1">
      <Typography maxLines={MAX_LINES} ref={titleRef} tooltip={isTitleOverflowing ? getTooltip(call.title) : undefined}>
        {call.title}
      </Typography>
      <Typography
        color={TextColor.SECONDARY}
        size={TypographySize.CAPTION}
        tooltip={isSubtitleOverflowing ? getTooltip(participantsText) : undefined}
        maxLines={MAX_LINES}
        ref={subtitleRef}
      >
        {participantsText}
      </Typography>
    </div>
  );
};

export default CallInfoCell;
