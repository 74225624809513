import { Pagination } from "./api.types";
import { DateRangeType } from "./date.types";

export interface GenerateGongOAuthStateResponse {
  data: {
    state: string;
  };
}

export interface GongAPIKeyPayload {
  accessKey: string;
  secretKey: string;
  apiBaseUrl: string;
}

export interface GongUser {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  title: string;
  phoneNumber: string;
  active: boolean;
}

export interface GongUsersData {
  users: GongUser[];
  pagination: Pagination;
}

export interface GongUsersResponse  {
  data: GongUsersData;
  message: string;
};


export interface GongCallsPayload {
  startTime?: DateRangeType;
  userIds?: string[];
}

interface GongCallParty {
  id: string;
  name: string;
  speakerId: string;
}

export interface GongCall {
  id: string;
  title: string;
  duration: number;
  callDate: string;
  parties: GongCallParty[];
}

export interface GongCallsData {
  calls: GongCall[];
  pagination: Pagination;
}

export interface GongCallsResponse  {
  data: GongCallsData;
  message: string;
}

export interface GongCreateProspectPayload {
  gongCallIds: string[];
}

export enum GongAuthMethod {
  OAUTH = 'OAUTH',
  API_KEY = 'API_KEY',
}
