import clsx from 'clsx';
import { useMemo } from 'react';
import { RadioGroup, Typography, TypographySize, YES_NO_OPTIONS, YesNoAnswer } from '../../../../components';
import { PROSPECT_TYPE_TO_LABEL } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setIsHidden, setIsRestricted } from '../../../../redux/reducers';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const CALL_TYPE_FIELD_HEIGHT_CLASSNAME = 'h-8';

const CallType = () => {
  const dispatch = useAppDispatch();
  const { prospectType, isHidden, isRestricted } = useAppSelector((state) => state.prospect.configuration);

  const fields = useMemo(
    () => [
      {
        label: 'Prospect purpose',
        content: (
          <Typography className={clsx('flex items-center', CALL_TYPE_FIELD_HEIGHT_CLASSNAME)} size={TypographySize.H5}>
            {PROSPECT_TYPE_TO_LABEL[prospectType]}
          </Typography>
        ),
      }, // Show the prospect purpose selected at the very beginning of prospect creation.
      {
        label: 'Is hidden?',
        content: (
          <RadioGroup
            className={CALL_TYPE_FIELD_HEIGHT_CLASSNAME}
            options={YES_NO_OPTIONS}
            value={isHidden ? YesNoAnswer.YES : YesNoAnswer.NO}
            onChange={(e) => dispatch(setIsHidden(e.target.value === YesNoAnswer.YES))}
          />
        ),
      },
      {
        label: 'Is restricted?',
        content: (
          <RadioGroup
            className={CALL_TYPE_FIELD_HEIGHT_CLASSNAME}
            options={YES_NO_OPTIONS}
            value={isRestricted ? YesNoAnswer.YES : YesNoAnswer.NO}
            onChange={(e) => dispatch(setIsRestricted(e.target.value === YesNoAnswer.YES))}
          />
        ),
      },
    ],
    [prospectType, isHidden, isRestricted]
  );

  return (
    <TabSection title="Call type">
      <div className="flex justify-between gap-4">
        {fields.map((field) => (
          <ProspectPageField key={field.label} label={field.label}>
            {field.content}
          </ProspectPageField>
        ))}
      </div>
    </TabSection>
  );
};

export default CallType;
