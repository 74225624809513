import { useEffect } from 'react';
import { useUpdateFilterParams } from '../../../hooks';
import { DateOptions, FilterKeys } from '../../../types';
import { SelectOption } from '../../shared';
import SidebarSelectFilter from './SidebarSelectFilter';
import { DATE_OPTIONS } from '../../../constants';

interface SidebarDateFilterProps {
  filterKey: FilterKeys;
  title: string;
  onChange: (newValue?: string[]) => void;
  selected?: SelectOption[];
  clearable?: boolean;
}

const SidebarDateFilter = ({ clearable = true, filterKey, selected, ...selectProps }: SidebarDateFilterProps) => {
  const updateFilterParams = useUpdateFilterParams();

  useEffect(() => {
    // Filter the selected options to only include valid date options.
    const validOptions = selected?.filter((option) => Object.values(DateOptions).includes(option.value as DateOptions));

    // If the number of valid options is different from the selected options,
    // update the filter params with the valid options.
    if (validOptions?.length !== selected?.length) {
      const validValues = validOptions?.map(({ value }) => value);
      updateFilterParams(filterKey, validValues);
    }
  }, [filterKey, selected, updateFilterParams]);

  return (
    <SidebarSelectFilter
      options={DATE_OPTIONS}
      placeholder="Select date range"
      selected={selected}
      clearable={clearable}
      {...selectProps}
    />
  );
};

export default SidebarDateFilter;
